import React from 'react';
import {
  EMPTYBAG,
  EMPTY_CART_CTA,
  SHOP_THE_MENU,
} from '../../../../constants/AnalyticsConstants';
import { BASE_URL } from '../../../../constants/App';
import useStore from '../../../../store/useStore';
import Button from '../../../Atoms/Button/Button';
import { handleEmptyCartRedirection } from '../../../../Utils/componentUtils';
import './EmptyCart.scss';

interface EmptyCartProps {
  emptyCartInfo?: any;
  SomethingWentWrong?: boolean;
  error?: any;
}
const EmptyCart: React.FC<EmptyCartProps> = ({
  emptyCartInfo,
  error,
  SomethingWentWrong = false,
}) => {
  const { setAttemptTracker, setAnalyticsProducts } = useStore();
  const handleShopTo = (ctaName: string) => {
    setAnalyticsProducts({
      clickActionName: SHOP_THE_MENU,
      pageName: EMPTYBAG,
    });
    setAttemptTracker([{ name: EMPTY_CART_CTA }]);
    setTimeout(() => {
      handleEmptyCartRedirection(emptyCartInfo.isStoreSelected);
    }, 0);
  };
  return (
    <div className="order__emptyCartInfo">
      {SomethingWentWrong && (
        <img
          className="order__emptyCartInfo_image"
          src={error.dictionaryLables.errorImage}
          alt=""
          data-testauto-id='order-empty-cart-image'
        />
      )}
      <h4 className="order__emptCart_title" data-testauto-id='order-empty-cart-title'>
        {SomethingWentWrong ? error.title : emptyCartInfo.emptyCartTitle}
      </h4>
      <p className="order__emptCart_description" data-testauto-id='order-empty-cart-desc'>
        {SomethingWentWrong ? error.message : emptyCartInfo.emptyCartDescription}
      </p>
      <Button
        testAutoId={'findAstore'}
        onClick={() => {
          handleShopTo(emptyCartInfo.emptyCartCTA);
        }}
        className="secondary__btn secondary__btn--upper secondary__btn--darkHover"
      >
        {SomethingWentWrong ? error.dictionaryLables.findAStoreCTA : emptyCartInfo.emptyCartCTA}
      </Button>
      {SomethingWentWrong && (
        <Button
          testAutoId={'returnToHomePage'}
          className="secondary__btn secondary__btn--upper secondary__btn--white secondary__btn--hover "
          onClick={() => {
            window.location.href = BASE_URL;
          }}
        >
          {error.dictionaryLables.returnToHomePage}
        </Button>
      )}
    </div>
  );
};

export default EmptyCart;
