import React, { useEffect } from 'react';
import classNames from 'classnames';
import { ButtonProps } from '../../../Interfaces/ButtonProps';
import './Button.scss';
import DancingDotsGreen from '../../../../src/assets/RemoteOrder/confettie/DancingDotsGreen.json';
import DancingDotsGrey from '../../../../src/assets/RemoteOrder/confettie/DancingDotsGrey.json';
import DancingDotsWhite from '../../../../src/assets/RemoteOrder/confettie/DancingDotsWhite.json';
import Lottie from 'lottie-react';
import { GREEN, GREY, WHITE } from '../../../constants/App';
import useStore from '../../../store/useStore';
import { disableInteractiveElements } from '../../../Utils/WindowUtils';

/**
 * Button component
 * @param {String} className class name
 * @param {Boolean} selected flag for selected
 * @param {Boolean} isLoading flag for loading
 * @param {Boolean} isDisabled flag for disable
 * @param {String} icon icon
 * @returns Reusable Button component
 */

const Button: React.FC<ButtonProps> = ({
  className = 'button',
  selected,
  isLoading,
  isDisabled,
  icon,
  testAutoId = 'button',
  ...buttonProps
}) => {
  const { contextLoader, setContextLoader } = useStore();
 
  const getAnimationData = (animationColor: 'white' | 'grey' | 'green') => {
    switch (animationColor) {
      case GREEN:
        return DancingDotsGreen;
      case GREY:
        return DancingDotsGrey;
      default:
        return DancingDotsWhite;
    }
  };
  const styleObj = (element:any) => {
      if(element && element.offsetHeight) 
        return { height:element.offsetHeight, width: element.offsetWidth }
  }

  const isLottieLoading = contextLoader?.isLoading && contextLoader.buttonId === testAutoId;

  useEffect(() => {
    if (isLottieLoading) {
      if (!document.body.classList.contains('disableInteraction')) {
        disableInteractiveElements();
      }
    }

    return () => {
      if (document.body.classList.contains('disableInteraction')) {
        disableInteractiveElements();
          setContextLoader({animationColor: WHITE, buttonId: '', isLoading: false});
      }
    };
  }, [isLottieLoading]);

  return (
    <button
      className={classNames(className, {
        'is-loading': isLoading,
        'is-disabled': isDisabled,
        [`${className}--outline`]: selected,
      })}
      data-testid={testAutoId}
      data-testauto-id={testAutoId}
      {...buttonProps}
      {...(contextLoader?.isLoading && contextLoader.buttonId == testAutoId && { "aria-label": contextLoader.ariaLabelText})}
    >
      {isLottieLoading ? (
        <Lottie
          className={`in-context-loader`}
          animationData={getAnimationData(contextLoader.animationColor)}
          style={styleObj(contextLoader.targetElement)}
        />
      ) : (
        <>
          {!!icon && icon}
          <span className="button__content">{buttonProps.children}</span>
        </>
      )}
    </button>
  );
};

export default Button;
