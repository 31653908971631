import React, { KeyboardEvent, useEffect, useState } from 'react';
import Button from '../Atoms/Button/Button';
import SvgIconClose from '../../assets/RemoteOrder/icons/Close.svg';
import ToastedIcon from '../../assets/RemoteOrder/icons/Customize.svg';
import GrilledIcon from '../../assets/RemoteOrder/icons/Grilled.svg';
import MinusIcon from '../../assets/RemoteOrder/icons/Minus_Black.svg';
import PlusIcon from '../../assets/RemoteOrder/icons/Plus_Black.svg';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import './IngredientCard.scss';
import { IngredientCardProps, ButtonProps } from '../Interfaces/IngredientCardInterface';
import Image from '../Atoms/Image/Image';
import Heading from '../Atoms/Heading/Heading';
import { BREAD } from '../../constants/App';
import { currencyPlacement } from '../../Utils/pageUtils';
import NutritionInfo from '../Molecules/NutritionInfo/NutritionInfo';
import { componentRenderCheck, multipleAndCheck, ternaryCheck} from '../../Utils/WindowUtils';
import useStore from '../../store/useStore';
import { GREEN } from '../../constants/App';
const _global = (window || global) as any;
/**
 * Ingredient Card component
 * @param {String} className
 * @param {String} optionName
 * @param {String} ingredientName
 * @param {String} ingredientOrginalName
 * @param {String} description
 * @param {String} ingredientCalories
 * @param {String} disclaimer
 * @param {String} removeBtnText
 * @param {Boolean} isSelected
 * @param {Boolean} inStock
 * @param {Function} onIngrdientClick
 * @param {Function} removeSelected
 * @param {Function} modifierOptionsClick
 * @param {String} imageUrl
 * @param {Array} buttonData
 * @param {Boolean} iconButton
 * @param {Boolean} isChoiceButtonEnabled enabling button to make a choice
 * @param {String} outOfStock translation value
 * @param {String} btnName translation value
 * @param {String} isDisabled translation value
 */
const IngredientCard: React.FC<IngredientCardProps> = ({
  className = '',
  optionName = '',
  ingredientName,
  ingredientOrginalName,
  description,
  ingredientCalories,
  disclaimer,
  removeBtnText,
  isSelected,
  inStock,
  onIngrdientClick,
  modifierOptionsClick,
  imageUrl,
  buttonData,
  iconButton,
  outOfStock,
  isChoiceButtonEnabled = false,
  btnName,
  isDisabled,
  upcharge = 0,
  optionId,
  ingredientDisplayName,
  id,
  forAnalaytics,
  toasted,
  grilled,
  toastedAndGrilled,
  isShowButton,
  min,
  max,
  nutritionInfoTitle,
  isUpsellModal = false,
  hasCombo = false,
  isPosition = false,
  nutrition = {},
  isCookiePack = false,
  uniqueTestId,
  isNavigationCombo = false,
  isAddtoCart
}) => {
  const [cookiesText, setCookiesText] = useState<any>('');
  const { setContextLoader } = useStore();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const removeSelected = (ID: string, name: any) => {
    if (!forAnalaytics) {
      return;
    }
    _global.analytics.prIngredientModifiers('cancel', 'combo', name);
   
  };
 
  const makeAreaLabelText = () =>
    ternaryCheck(
        !inStock,
        `${outOfStock} ${ingredientName} ${ingredientCalories}`,
        ternaryCheck(
            hasCombo,
            `${ingredientName} ${btnName}`,
            `${ingredientName} ${btnName}`
        )
    );
 
 
  const isDefaultChangable = ternaryCheck(
    multipleAndCheck(buttonData, ingredientDisplayName == BREAD),
    buttonData?.some((subOption: any) => subOption.isDefaultChangeable),
    true,
  );
  const showBreadIcons = (
    ingredientNames: string,
    toastedAndGrilleds: string,
    toasted_item: string,
    grilled_item: string,
  ) => {
   
    return componentRenderCheck(
      ingredientNames.includes(toastedAndGrilleds),
      (
          <>
              <ToastedIcon />
              <GrilledIcon />
          </>
      )
    ) || componentRenderCheck(ingredientNames.includes(toasted_item),<ToastedIcon />) 
      || componentRenderCheck(ingredientNames.includes(grilled_item),<GrilledIcon />);
  };
 
  const dataName = `${ingredientName}`.replace(/\s+/g, '-').toLowerCase();
  return (
    <>
      <div
        className={classNames(`cardcomponent ${className}`, {
          'cardcomponent--sel': multipleAndCheck(isSelected, inStock, !isChoiceButtonEnabled),
          'cardcomponent--stock': !inStock,
          'cardcomponent--close': iconButton,
        })}
        data-testid={'modifier-options-id-1'}
        data-testauto-id={dataName}
        role={!multipleAndCheck(isSelected, inStock, !isChoiceButtonEnabled) ? 'radio' : ''}
        aria-checked={multipleAndCheck(isSelected, inStock)}
        aria-label={ternaryCheck(
          isUpsellModal,
          null,
          ternaryCheck(
            !inStock,
            `${outOfStock} ${ingredientName} ${ingredientCalories}`,
            `${ingredientName} ${ingredientCalories}`
          )
        )}
        tabIndex={ternaryCheck(isUpsellModal, -1, 0)}
        onKeyPress={event => {
          if (event.key === 'Enter') onIngrdientClick();
        }}
        onClick={ternaryCheck(!isChoiceButtonEnabled, onIngrdientClick, () => console.log())}
      >
        <div className="cardheader">
          {componentRenderCheck(
            !!imageUrl,
            <div className="cardheaderleft">
              <Image
                className="cardheaderleft__img"
                alt=""
                imageSources={imageUrl || []}
                testAutoId={`card-header-left-${uniqueTestId}`}
              />
            </div>,
          )}
          <div className="cardheadermiddle">
            {componentRenderCheck(
              !inStock,
              <Heading
                className="cardcomponent__stock-out"
                level="h4"
                title={outOfStock}
                testAutoId={`out-of-stock-${uniqueTestId}`}
              />,
            )}
            <div
              className="cardcomponent__heading"
              data-testauto-id={`ingred-name-${uniqueTestId}`}
            >
              {optionName && `${optionName} - `}
              {ingredientName}
            </div>
            {componentRenderCheck(
              !!description,
              <div
                className="cardcomponent__heading cardcomponent__heading--second"
                data-testauto-id={`ingred-desc-${uniqueTestId}`}
              >
                {description}
              </div>,
            )}
            <div className="cardcomponent__price">
              <div data-testauto-id={`ingredient-cal-${uniqueTestId}`}>
                {buttonData?.map((button: ButtonProps) =>
                  componentRenderCheck(
                    multipleAndCheck(button.price, isSelected, button.selected),
                    <React.Fragment key={button?.price}>
                      +{currencyPlacement('$', button?.price?.toFixed(2))}&nbsp;&bull;&nbsp;
                    </React.Fragment>,
                  ),
                )}
                {upcharge > 0 && `+${currencyPlacement('$', upcharge.toFixed(2))} • `}{' '}
                {ingredientCalories}
              </div>
            </div>
          </div>
          {componentRenderCheck(
            ingredientDisplayName === BREAD,
            <div className="cardcomponent__icons">
              {showBreadIcons(ingredientName!, toastedAndGrilled!, toasted!, grilled!)}
            </div>,
          )}
          {componentRenderCheck(
            multipleAndCheck(isSelected, inStock, removeBtnText, !isShowButton),
            <div className="cardheaderright">
              <Button
                testAutoId={`remove-${uniqueTestId}`}
                className={classNames('primary__btn primary__btn--rounded', {
                  'primary__btn--onlyIcon': iconButton,
                })}
                tabIndex={ternaryCheck(!!inStock,0,-1)}
                icon={componentRenderCheck(!!iconButton,<SvgIconClose />)}
                onClick={() => removeSelected(id, ingredientName)}
                aria-label={`${removeBtnText} ${ingredientName}`}
              >
                {removeBtnText}
              </Button>
            </div>,
          )}
      {isNavigationCombo && (
            <div className="cardholder">
              {isSelected &&
                isDefaultChangable &&
                inStock &&
                buttonData &&
                buttonData.map((button: ButtonProps, index: number) => (
                  <>
                    <Button
                      testAutoId={`${button.name}`.replace(/\s+/g, '-').toLowerCase()}
                      key={index}
                      className={`primary__btn primary__btn--count${buttonData.length} cardholder__roundbtn ${button.selected?'disabled':'enabled'}`}
                      selected={button.selected}
                      tabIndex={ternaryCheck(inStock, 0 ,-1)}
                      aria-label={`${button.name == '2'?`Increase quantity ${ingredientName}`:`Decrease quantity ${ingredientName}`}  ${button.selected? '(disabled)':''}`}
                      onKeyDown={(e: KeyboardEvent) => {
                        if (e.key === 'Enter' && modifierOptionsClick && !button.selected) {
                          modifierOptionsClick(
                            e,
                            button,
                            ingredientName,
                            optionId,
                            ingredientDisplayName,
                            ingredientOrginalName,
                          );
                          setCookiesText(`${button.name} ${ingredientName}`);
                        }
                      }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        if (!button.selected && modifierOptionsClick) {
                          modifierOptionsClick(
                            e,
                            button,
                            ingredientName,
                            optionId,
                            ingredientDisplayName,
                            ingredientOrginalName,
                          );
                          setCookiesText(`${button.name} ${ingredientName}`);
                        } else {
                          return;
                        }
                      }}
                    >
                      {ternaryCheck(button.name == '1',<MinusIcon />,<PlusIcon />)}
                    </Button>
                    {button.selected && <span className="btn_value">{button.name}</span>}
                  </>
                ))}
                <span aria-live="assertive" aria-atomic="true" className="hidden__text">{cookiesText}</span>
            </div>
          )}
        </div>
        {!isNavigationCombo && (
          <div className="cardholder" role="radiogroup">
            {isSelected &&
              isDefaultChangable &&
              inStock &&
              buttonData &&
              buttonData.map((button: ButtonProps, index: number) => (
                <Button
                  testAutoId={`${button.name}`.replace(/\s+/g, '-').toLowerCase()}
                  key={index}
                  className={`primary__btn primary__btn--count${buttonData.length} cardholder__btn primary__btn--full-width`}
                  selected={button.selected}
                  isDisabled={!button.inStock}
                  tabIndex={ternaryCheck(inStock, 0,-1)}
                  aria-checked={button.selected}
                  role={ternaryCheck(inStock,'radio','')}
                  onKeyPress={(e: KeyboardEvent) => {
                    if (e.key === 'Enter' && modifierOptionsClick && button.inStock)
                      modifierOptionsClick(
                        e,
                        button,
                        ingredientName,
                        optionId,
                        ingredientDisplayName,
                        ingredientOrginalName,
                      );
                  }}
                  onClick={(e: any) =>
                    modifierOptionsClick && button.inStock && 
                    modifierOptionsClick(
                      e,
                      button,
                      ingredientName,
                      optionId,
                      ingredientDisplayName,
                      ingredientOrginalName,
                    )
                  }
 
                >
                  {button.name}
                </Button>
              ))}
          </div>
        )}
       
        {componentRenderCheck(
          !!disclaimer,
          <Heading
            className="carddisclaimer"
            level="p"
            title={disclaimer}
            testAutoId={`disclaimer-${uniqueTestId}`}
          />,
        )}
        {componentRenderCheck(
          !!isChoiceButtonEnabled,
          <Button
            testAutoId={dataName}
            className={classNames('secondary__btn secondary__btn--white secondary__btn--upper', {
              'secondary__btn--position': isPosition,
            })}
            onClick={() => { 
              onIngrdientClick();
              !isAddtoCart ?
              setContextLoader({
                buttonId: dataName,
                isLoading: true,
                animationColor: GREEN
              }) : '';
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                onIngrdientClick();
              }
            }}
            isDisabled={isDisabled}
            aria-label={makeAreaLabelText()}
          >
            {btnName}
          </Button>,
        )}
         </div>
      {componentRenderCheck(
        multipleAndCheck(isSelected, isCookiePack, !_isEmpty(nutrition)),
        <NutritionInfo nutritionTitle={nutritionInfoTitle} nutrition={nutrition} />,
      )}
    </>
  );
};
 
export default IngredientCard;