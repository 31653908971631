import React, { useState } from 'react';
import '../../../IngredientCard/IngredientCard.scss';
import './QuickAddOn.scss';
import Image from '../../../Atoms/Image/Image';
import { priceCaloriesConverter } from '../../../../Utils/componentUtils';
import Button from '../../../Atoms/Button/Button';
import { reactEngageApptentiveEvent } from '../../../../Utils/pageUtils';
import Plus from '../../../../assets/RemoteOrder/img/plus.svg';
import Checkmark from '../../../../assets/RemoteOrder/icons/filled-checkmark.svg';
import { disableInteractiveElements, navigateToMenu } from '../../../../Utils/WindowUtils';
import { ADDONS_PLUS_ICON } from '../../../../constants/ContextConstants';
import useStore from '../../../../store/useStore';

interface CardInterface {
  id: number;
  imageUrl?: [] | string;
  productName: string;
  price?: string;
  calories?: string;
  cals: string;
  add: string;
  onClickAddon: () => void;
  addOnsNameTestAutoId?: string;
  addOnsPriceAndCalTestAutoId?: string;
  addOnsImageNameTestAutoId?: string;
  fromModalCarousel?: boolean;
  showTickMarkIcon?: boolean;
  showViewButton?: boolean;
  viewMenuLabel?: string;
}

const Card: React.FC<CardInterface> = ({
  id,
  onClickAddon,
  imageUrl,
  productName,
  price,
  cals,
  calories,
  addOnsNameTestAutoId,
  addOnsPriceAndCalTestAutoId,
  addOnsImageNameTestAutoId,
  fromModalCarousel,
  showTickMarkIcon,
  add,
  showViewButton = false,
  viewMenuLabel = ''
}) => {
  const {
    setContextLoader,
  } = useStore();
  const [showContextLoader, setShowContextLoader] = useState(false)
  const handleOnClickAddon = () => {
    onClickAddon()
    setContextLoader({
      buttonId: ADDONS_PLUS_ICON + `-${id}`,
      isLoading: true,
      animationColor: 'green'
    });
    setShowContextLoader(true)
  }
  if (showTickMarkIcon && showContextLoader) {
    disableInteractiveElements();
    setShowContextLoader(false)
  }
  const renderUI = () => {
    return (
      <>
        {!showViewButton && (
          <>
            <div className="addons-image">
              <Image imageSources={imageUrl || ''} testAutoId={addOnsImageNameTestAutoId} />
            </div>
            <div className="addons-text">
              <div className="addons-title" data-testauto-id={addOnsNameTestAutoId}>
                {productName}
              </div>
              <div className="addons-price">
                <div data-testauto-id={addOnsPriceAndCalTestAutoId}>
                  {priceCaloriesConverter(price, calories, cals, null, null)}
                </div>
              </div>
            </div>
            {fromModalCarousel && (
              <Button
                testAutoId={ADDONS_PLUS_ICON + `-${id}`}
                className={`${ADDONS_PLUS_ICON} ${showTickMarkIcon ? 'disable-icon' : ''}`}
                aria-label={`${add} ${productName} $${price} ${calories} ${cals}`}
                onClick={() => {
                  reactEngageApptentiveEvent('cart quick add ons', true);
                  handleOnClickAddon();
                }}
              >
                {showTickMarkIcon ? <Checkmark /> : <Plus />}
              </Button>
            )}
          </>
        )}
        {showViewButton && (
          <Button
            testAutoId={'addons-view-menu'}
            className={'addons-view-menu primary__btn primary__btn--textOnly'}
            onClick={() => navigateToMenu()}
          >
            {viewMenuLabel}
          </Button>
        )}
      </>
    );
  };
  return (
    <>
      {fromModalCarousel ? (
        <div className={`addons-card  ${showContextLoader && 'disabledElementOverlay'}`}>{renderUI()}</div>
      ) : (
        <Button
          testAutoId={`add-on-btn-${id}`}
          onClick={() => {
            reactEngageApptentiveEvent('cart quick add ons', true);
            onClickAddon();
          }}
          className="addons-card"
        >
          {renderUI()}
        </Button>
      )}
    </>
  );
};

export default Card;