import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './FloatingListItems.scss'
import Checkmark from '../../../assets/RemoteOrder/icons/CheckMark.svg';
import Button from '../../Atoms/Button/Button';
import { SELECT_PICKUPTIME } from '../../../constants/AnalyticsConstants';
import Lottie from 'lottie-react';
import DancingDotsGrey from '../../../../src/assets/RemoteOrder/confettie/DancingDotsGrey.json';
import Modal from '../../Molecules/Modal/Modal';
import UseWindowWidth from '../../../hooks/useWindowWidth';
import { addFocusListener, onTabEsc } from '../../../Utils/componentUtils';
import useStore from '../../../store/useStore';
import { multipleAndCheck, ternaryCheck } from '../../../Utils/WindowUtils';
import _get from 'lodash/get'

interface FloatingListItemProps {
    allList: { analyticsText: string, text: string, value: string }[],
    dictionaryLabels: any,
    handleSelectedPickupTime: any,
    showContextLoader: boolean,
    handleSetShowPickupList: () => void,
    editBtnRef: any,
    selectedTime: string,
    isShowPickupList: boolean
}

const FloatingListItems: React.FC<FloatingListItemProps> = ({ allList, dictionaryLabels, handleSelectedPickupTime, showContextLoader, handleSetShowPickupList, selectedTime, editBtnRef, isShowPickupList }) => {
    const { setSelectedPickupTimeIndex, selectedPickupTimeIndex } = useStore();
    const selectedTimeVal = selectedTime;
    const popupRef = useRef<HTMLDivElement>(null);
    const firstFocusableElementRef = useRef<any>(null);
    const lastFocusableElementRef = useRef<any>(null);
    const windowSize = UseWindowWidth()

    useEffect(() => {
        if (isShowPickupList) {
            if (popupRef.current) {
                const focusableElements = popupRef.current.querySelectorAll('button');
                if (focusableElements.length > 0) {
                    focusableElements[0].focus()
                }
            }
            addFocusListener(popupRef, firstFocusableElementRef, lastFocusableElementRef);
        }
        const reactPopup = document.getElementById('react-popup') as HTMLElement;
        const modalPopup = reactPopup?.querySelector('.modalPopup');
        const focusModalPopup = reactPopup?.querySelector('.focus-modal-popup');
        const stickTo = document.getElementById('stick-to') as HTMLElement;
        const headerSticky = stickTo?.querySelector('.header-sticky');
        const homePageNew = stickTo?.querySelector('#homePageNew');
        const componentFooter = stickTo?.querySelector('.component_footer');
        const htmlElement = document.querySelector('.cms') as HTMLElement;

        if (modalPopup && focusModalPopup && headerSticky && homePageNew && componentFooter) {
            focusModalPopup.setAttribute('aria-hidden', isShowPickupList ? 'false' : 'true');
            headerSticky.setAttribute('aria-hidden', isShowPickupList ? 'true' : 'false');
            homePageNew.setAttribute('aria-hidden', isShowPickupList ? 'true' : 'false');
            componentFooter.setAttribute('aria-hidden', isShowPickupList ? 'true' : 'false');
            htmlElement?.style?.setProperty('overflow-y', 'hidden');
        }
        return () => {
            if (focusModalPopup && headerSticky && homePageNew && componentFooter) {
                focusModalPopup.removeAttribute('aria-hidden');
                headerSticky.removeAttribute('aria-hidden');
                homePageNew.removeAttribute('aria-hidden');
                componentFooter.removeAttribute('aria-hidden');
            }
            htmlElement?.style?.setProperty('overflow-y', 'initial');
        };
    }, [isShowPickupList]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(event.target) &&
                editBtnRef.current &&
                !editBtnRef.current.contains(event.target)
            ) { handleSetShowPickupList(); }
        };
        const handleKeyDown = (event: { key: string; keyCode: number; }) => {
            if (event.key === 'Escape' || event.keyCode === 27) {
                handleSetShowPickupList();
            }
        };
        document.addEventListener('click', handleClickOutside);
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('click', handleClickOutside);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleDefaultPickupTime = (val: { text: string, value: string }, index: number) => {
        return (
            <>
                <p className='floatingListItem__items' id="pickupTime_ariaLabel">
                    {index === 0 ?
                        (<>{dictionaryLabels?.nextAvailableTime} <span>{' \u00B7 '}</span>{val.text.split(`\u00B7`)[0]}</>)
                        :
                        (val.text)
                    }
                </p>
                <p> {selectedTimeVal == val.value && !showContextLoader && <Checkmark />} </p>
            </>
        )
    }
    const handleSelectPickupTime = (val: { analyticsText: string, text: string, value: string }, index: number) => {
        const analyticsText = _get(val, ['analyticsText'], '');
        if (multipleAndCheck(!showContextLoader, selectedTimeVal !== val.value)) {
            setSelectedPickupTimeIndex(index);
            handleSelectedPickupTime(val.value, analyticsText)
        }
    }

    const generateTemplate = () => {
        return (
            <div ref={popupRef} data-testid={'floatingListItem__wrapper--ID'} data-testauto-id={'floatingListItem__wrapper--ID'} className={`floatingListItem__wrapper`} id="floatingListItem__wrapper--ID">

                {allList?.map((val, index) => {
                    return (
                        <div key={index} className='floatingListItem__mainContent' >
                            <Button
                                testAutoId={`${SELECT_PICKUPTIME}_${index}`}
                                className={`floatingListItem__listItems  ${ternaryCheck(multipleAndCheck(index === selectedPickupTimeIndex, showContextLoader), 'floatingListItem__disabled', '')}`}
                                onClick={() => handleSelectPickupTime(val, index)}
                                aria-label={ternaryCheck(multipleAndCheck(index === selectedPickupTimeIndex, showContextLoader), "updating", '')}
                            >
                                {handleDefaultPickupTime(val, index)}
                                {
                                    multipleAndCheck(index === selectedPickupTimeIndex, showContextLoader) && (
                                        <span className='floatingListItem__contextLoader' data-testid={'floatingListItem-context-loader'} data-testauto-id={'floatingListItem-context-loader'}>
                                            <Lottie animationData={DancingDotsGrey} />
                                        </span>
                                    )
                                }
                            </Button>
                        </div>
                    )
                })}
            </div >
        )
    }

    return (<>
        {
            windowSize < 1024 ?
                (<Modal
                    componentParentClass={`floatingListItems__modal`}
                    showCurvedTopModal={false}
                    onButtonClick={() => handleSetShowPickupList()}
                    isModalBottomSlide={window.screen.width < 480 ? true : false}
                    testId="floatingListItems__container"
                    isClosebtn={true}
                    title={dictionaryLabels.selectaPickupTime}
                >

                    {generateTemplate()}
                </Modal>
                ) : (

                    generateTemplate()
                )
        }
    </>

    )
}

export default FloatingListItems