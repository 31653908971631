import React from 'react';
interface InnerHTMLInterface {
  Tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'span';
  className?: string;
  text: string | React.JSX.Element;
  onClick?: (e: any) => void;
  testId?: string;
  testAutoId?: string;
}
/**
 * InnerHTML component
 * @param param0.Tag Tag can be any of these 'h1' to 'h5', 'p','span'
 * @param param0.text text to be set in the element.
 * @param param0.className custom class name
 * @returns react innerHTML component
 */

const InnerHTML: React.FC<InnerHTMLInterface> = ({ Tag = 'h1', className, text, onClick, testId, testAutoId }) => {
  return <Tag data-testid={testId} className={className} onClick={onClick} dangerouslySetInnerHTML={{ __html: text }}  data-testauto-id={testAutoId}></Tag>;
};
export default InnerHTML;
