import React from 'react';
import Button from '../../../Atoms/Button/Button';
import Previous from '../../../../assets/RemoteOrder/icons/Previous.svg';
import Next from '../../../../assets/RemoteOrder/icons/Next.svg';

const Controls = ({ className, btnName, ariaLabel, currentSlide, onClick, buttonProps }: any) => {
  return (
    <>
      <Button
        testAutoId={`${btnName}-btntestid`}
        className={className}
        aria-label={btnName}
        aria-disabled={ariaLabel}
        data-currentslide={currentSlide}
        tabIndex = {0}
        onClick={onClick}
      >
        {btnName.includes('Previous') ? <Previous /> : <Next />}
        {buttonProps}
      </Button>
    </>
  );
};

export default Controls;
