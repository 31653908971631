import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { renderNestedComponents } from './Render';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import useStore from './store/useStore';
import {
  transformRenderComponents,
  getAppConfig,
  pageName,
  getComponentList,
  checkRewardsPageCondition
} from './Utils/pageUtils';
import { createCartApi, pageLayoutApi } from './APIs/Api';
import { useQuery, useMutation } from '@tanstack/react-query';
import { calculateHeaderHeight, getStoreIDByURL} from './Utils/componentUtils';
import { OK_CTA } from './constants/App';
import CreateCartErrors from './components/Molecules/CreateCart/CreateCartErrors';
import useAnalytics from './hooks/useAnalyticsObject';
import ErrorModal from './components/Molecules/CartModal/ErrorModal';
import UseWindowWidth from './hooks/useWindowWidth';

const App = () => {
  const {
    setAppConfig,
    pageLoadAnalytics,
    setAttemptTracker,
    setAnalyticsProducts,
    setNoOfComponents,
    noOFComponents,
    setCartID,
    setIsLoggedIn,
    headerHeightCal,
    setHeaderHeight,
    bannerHeightCal,
    setPageDisplayName,
    pageDisplayName,
  } = useStore();
  const [showModal, isShowModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [showClose, isShowClose] = useState(false);
  const [cartResponse, setCartResponse] = useState();
  const header = document.querySelector('.header-sticky');
  const headerHeight = _get(header, ['offsetHeight'], 0);
  const windowsWidth = UseWindowWidth() <= 1023 ? true : false;
  const path = window.location.pathname.split('/')[3] == 'order-management';

  const excludedPage = ['home', 'order-confirmation'];
  const headerClass = document.querySelector('.navbar');
  const headerClassHeight = _get(headerClass, ['offsetHeight'], 0);
  const header2 = document.querySelector('.defaultHeader__comp');
  const headerHeight2 = _get(header2, ['offsetHeight'], 0);
  const comboHeader = document.querySelector('.trackerheader');
  const comboHeaderHeight = _get(comboHeader, ['offsetHeight'], 0);
  const stickyHeader = document.querySelector('.stickyheader__top');
  const sticky = _get(stickyHeader, ['offsetHeight'], 0);
  React.useEffect(() => {
    if (orderViewModel.isNavigateCombo || orderViewModel.isApplyCombo) {
      setHeaderHeight(parseInt(comboHeaderHeight || sticky) + 'px');
    } else {
      setHeaderHeight(
        parseInt(headerClassHeight) +
          parseInt(headerHeight2) +
          parseInt(comboHeaderHeight) +
          parseInt(bannerHeightCal) +
          'px',
      );
    }
  }, [headerClassHeight, headerHeight2, bannerHeightCal, comboHeaderHeight]);
  useEffect(() => {
    if (headerHeight) {
      const reactContainer = document.querySelector('.react-container');
      if (path && windowsWidth) {
        header.querySelectorAll('.defaultStoreHeader')[0].style.display = 'none';
        const navbar = header.querySelectorAll('.navbar.cms');
        if (navbar && !_isEmpty(navbar)) {
          navbar[0].style.display = 'flex';
        }
        reactContainer.style.marginTop = `${_get(header, ['offsetHeight'], 0)}px`;
        return;
      }
      if (path && !windowsWidth) {
        header.querySelectorAll('.defaultStoreHeader')[0].style.display = 'none';
        reactContainer.style.marginTop = `${_get(header, ['offsetHeight'], 0)}px`;
        return;
      }
      if (reactContainer) {
        reactContainer.style.marginTop = `${headerHeight}px`;
      }
    }
  }, [headerHeight, windowsWidth]);

  useLayoutEffect(() => {
    if (pageLoadAnalytics === 1 && noOFComponents.length === 1) {
      setAnalyticsProducts({});
      setAttemptTracker([{ name: 'menu_pageview' }]);
    }
    if (pageLoadAnalytics === 2 && noOFComponents.length === 2) {
      setAnalyticsProducts({});
      setAttemptTracker([{ name: 'menu_pageview' }]);
    }
    if (pageLoadAnalytics === 3 && noOFComponents.length === 3) {
      setAnalyticsProducts({});
      setAttemptTracker([{ name: 'menu_pageview' }]);
    }
  }, [pageLoadAnalytics]);

  const closePopup = useCallback(() => {
    isShowModal(false);
    setAnalyticsProducts({});
  }, []);

  const { data: pageLayout, isLoading } = useQuery({
    queryKey: ['pageLayout'],
    queryFn: pageLayoutApi,
  });

  const appConfig1 = pageLayout && getAppConfig(pageLayout);
  const pageDeails = pageName(pageLayout);
  useAnalytics(pageDeails);

  const getPageName = pageLayout && pageLayout.sitecore.route.fields.pageDisplayName.value;
  if(getPageName && !pageDisplayName){
    setPageDisplayName(getPageName);
  }

  useEffect(() => {
    setAppConfig(appConfig1);
    setIsLoggedIn(pageDeails.loggedIn ? true : false);
  }, [appConfig1, setAppConfig, pageDeails.loggedIn]);

  const renderComponents = useMemo(
    () => pageLayout && transformRenderComponents(pageLayout),
    [pageLayout],
  );

  const { mutateAsync, error } = useMutation(createCartApi);

  useEffect(() => {
    const isNonTransactional = _get(
      pageLayout,
      ['sitecore', 'route', 'fields', 'isNonTransactional', 'value'],
      false,
    );
    const fetchData = async () => {
      if (
        !orderViewModel.currentOrder.cartId &&
        !isNonTransactional &&
        checkRewardsPageCondition(pageLayout.sitecore.route.name) &&
        pageLayout.sitecore.route.name !== 'order-management' &&
        pageLayout.sitecore.route.name !== 'my-deals' &&
        pageLayout.sitecore.route.name !== 'paymentmethods' &&
        pageLayout.sitecore.route.name !== 'recent-orders' &&
        pageLayout.sitecore.route.name !== 'profile' &&
        pageLayout.sitecore.route.name !== 'contactinfo' &&
        pageLayout.sitecore.route.name !== 'requestmissingpoints' &&
        pageLayout.sitecore.route.name !== 'locator' &&
        pageLayout.sitecore.route.name !== 'deliverylocator' &&
        pageLayout.sitecore.route.name !== 'my-favorites' &&
        !excludedPage.includes(pageLayout.sitecore.route.name)
      ) {
        const data = await mutateAsync({
          storeId: getStoreIDByURL(),
          DirectDelivery: orderViewModel.DeliveryAddress.DirectDelivery,
          SiteName: siteName,
          IsJsonResponse: true,
          OrderType: orderViewModel.orderType,
          StreetAddressLine1: orderViewModel.DeliveryAddress.StreetAddressLine1,
          StreetAddressLine2: orderViewModel.DeliveryAddress.StreetAddressLine2,
          City: orderViewModel.DeliveryAddress.City,
          State: orderViewModel.DeliveryAddress.State,
          Zip: orderViewModel.DeliveryAddress.Zip,
          Country: orderViewModel.DeliveryAddress.Country,
        });
        setCartResponse(data);
      }
    };
    if (pageLayout) {
      setNoOfComponents(getComponentList(pageLayout));
      fetchData();
    }
  }, [mutateAsync, orderViewModel, pageLayout]);

  useEffect(() => {
    if (cartResponse) {
      if (!cartResponse.isStoreOpen && !cartResponse.error) {
        isShowModal(true);
        isShowClose(false);
        setModalData(cartResponse.errorData);
      } else if (!cartResponse.isWebOrderAvailable && !cartResponse.error) {
        isShowModal(true);
        isShowClose(true);
        setModalData(cartResponse.errorData);
      } else if (cartResponse.error) {
        isShowModal(true);
        isShowClose(true);
        setModalData(cartResponse.error);
      } else {
        isShowModal(false);
        setModalData('');
        isShowClose(false);
        orderViewModel.currentOrder.cartId = cartResponse.cart.cartId;
        setCartID(cartResponse.cart.cartId);
        orderViewModel.storeStatus = cartResponse.isStoreOpen;
        orderViewModel.cartOpen = true;
        cartResponse.store.storeId = cartResponse.store.locationId;
        subwayScript.reactUpdateStoreDetails(cartResponse.store);
        orderViewModel.save();
        subwayScript.setCookie('CartId', cartResponse.cart.cartId, 90);
      }
    }
  }, [cartResponse]);

  useEffect(() => {
    calculateHeaderHeight(headerHeightCal);
  }, [headerHeightCal]);
  useEffect(() => {
    // Fetch data from API
    setAppConfig(appConfig1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Suspense fallback={null}>
      {renderComponents && renderNestedComponents(renderComponents)}
      {showModal && modalData && (
        <CreateCartErrors
          showModal={showModal}
          modalData={modalData}
          closePopup={closePopup}
          showClose={showClose}
          okText={okText ? okText : OK_CTA}
          name={pageDeails.pageName}
        />
      )}
      {error && <ErrorModal error={error} okText={okText} value={pageDeails.pageName} />}
    </React.Suspense>
  );
};
export default App;
