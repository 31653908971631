import React, { useState } from 'react';
import classnames from 'classnames';
import _isString from 'lodash/isString';
import { DEFAULT_IMAGE } from '../../../constants/App';
import _isEmpty from 'lodash/isEmpty';
import './Image.scss';

export interface ImageSources {
  srcset: string;
  size: number;
  type: string;
}
export interface ImageProps {
  className?: string;
  alt?: string;
  loadingType?: 'lazy' | 'eager';
  imageSources: ImageSources[] | string;
  onClick?: () => void;
  tabIndex?: number;
  title?: string;
  testAutoId?: string;
  imageSize?: string;
}


/**
 * reusable Image component
 * @param className dynamic class name for image
 * @param alt alternative text for image
 * @param imageSources dynamic image sources for different screen sizes
 * @param testAutoId is unique id added for automation
 * @returns react image component
 */
const Image: React.FC<ImageProps> = ({
  className,
  alt = '',
  imageSources = DEFAULT_IMAGE,
  loadingType = 'lazy',
  testAutoId,
  imageSize = '',
  ...props
}) => {
  const imgURL = _isString(imageSources) ? imageSources : imageSources && imageSources[0]?.srcset;
  const [isLoaded, setIsLoaded] = useState(false);
  const imgClassName = classnames('picture-container__img', {
    [`picture-container__img--${imageSize}`]: !_isEmpty(imageSize) && !isLoaded,
  })
  const handleLoad = () => {
    setIsLoaded(true);
  };
  return (
    <picture {...props} className={classnames('picture-container', className)}>
      {imageSources &&
        Array.isArray(imageSources) &&
        imageSources.map((image, index) => (
          <source
            key={image.size}
            media={`(min-width: ${image.size}px)`}
            srcSet={image.srcset}
            type={image.type}
          />
        ))}
      <img
        src={imgURL}
        alt={alt}
        loading={loadingType}
        data-testauto-id={testAutoId}
        className={imgClassName}
        onLoad={handleLoad}
      />
    </picture>
  );
};

export default Image;
