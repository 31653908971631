import React, { useRef, useState } from 'react';
import Heading from '../../../Atoms/Heading/Heading';
import Card from './Card';
import UseWindowWidth from '../../../../hooks/useWindowWidth';
import CustomSlider from '../../../Molecules/CustomSlider/CustomSlider';
import _isEmpty from 'lodash/isEmpty';
import './QuickAddOn.scss';
import { ternaryCheck } from '../../../../Utils/WindowUtils';

interface ProductDetails {
  productName: string;
  productId: string;
  categoryName: string;
  categoryId: string;
  analyicsProductName: string;
}
interface QuickAddOnProps {
  quickaddonResponse: any;
  dictionaryLables: any;
  onClickQuickAddonHandler: (productDetails: ProductDetails, i: number, length: number) => any;
  fromModalCarousel: boolean;
  viewMenuLabel?: string
  sliderAlignment?: string
}

const QuickAddOn: React.FC<QuickAddOnProps> = ({
  quickaddonResponse,
  dictionaryLables,
  onClickQuickAddonHandler,
  fromModalCarousel,
  viewMenuLabel = '',
  sliderAlignment
}) => {
  const windowWidth = UseWindowWidth();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const renderQuickAddons = (data: any) => {
    return data.map((item: any, index: number) => {
      return (
        <Card
          key={item.id}
          id={item.id}
          imageUrl={item.imageUrl}
          productName={item.displayName || item.name}
          price={item.price}
          calories={item.calories || item.calorie}
          onClickAddon={() => onClickQuickAddonHandler(item, index, quickaddonResponse.length)}
          cals={dictionaryLables.cals}
          add={dictionaryLables.add}
          addOnsNameTestAutoId={`add-ons-name-${index}`}
          addOnsPriceAndCalTestAutoId={`add-ons-price-cal-${index}`}
          addOnsImageNameTestAutoId={`add-ons-img-${index}`}
          fromModalCarousel={fromModalCarousel}
          showTickMarkIcon={item.isTickMarkIcon}
          showViewButton={item.showViewButton}
          viewMenuLabel={viewMenuLabel}
        />
      );
    });
  };
  const sliderRef = useRef<any>(null);

  if (sliderRef.current && viewMenuLabel) {
    setTimeout(() => {
      sliderRef.current?.getElementsByClassName('slider-list')[0].children.forEach((element: any) => {
        element.removeAttribute("inert")
      });
    }, 1000);
  }
  
  return (
    <>
      {!_isEmpty(quickaddonResponse) && (
        <div className="quickaddon__container">
          <Heading className="quickaddons-title" level="h4" title={dictionaryLables.quickAddOns} testAutoId='quick-add-ons-title' />
          {(windowWidth > 1024 && !fromModalCarousel) ? (
            <div className="addons-container addons-list">
              {renderQuickAddons(quickaddonResponse)}
            </div>
          ) : (
            <div className="addons-container addons-slider">
              <CustomSlider
                slides={quickaddonResponse.length}
                slideCount={quickaddonResponse.length - 1}
                isWithoutControls={true}
                isQuickAddOns={true}
                fromModalCarousel={fromModalCarousel}
                sliderRef={sliderRef}
                getCurrentSlideIndex={index => setCurrentSlideIndex(index)}
                cellAlignment={ternaryCheck(sliderAlignment == 'center', 'center', 'left')}
              >
                {renderQuickAddons(quickaddonResponse)}
              </CustomSlider>
            </div>
          )}
        </div >
      )}
    </>
  );
};

export default QuickAddOn;