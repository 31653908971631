import React, { forwardRef } from 'react';

interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string;
  type: string;
  name:string;
  placeholder?: string;
    ref?: React.ForwardedRef<HTMLInputElement>;
  props?: React.ReactNode;
  dataTestId?: string;
  maxLength?: number;
  autoComplete?: string;
}


/**
 * reusable Input component
 * @param className dynamic class name for input
 * @param ref for input box
 * @param type for input box
 * @param props  extra props input box
 * @returns react Input component
 */

const Input: React.FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  ({ type, name,className,placeholder,dataTestId, maxLength,autoComplete, ...props}, ref) => {
    return (
      <input
        maxLength={maxLength}
        type={type}
        ref={ref}
        name={name}
        placeholder={placeholder}
        className={className}
        data-testid={dataTestId}
        data-testauto-id={dataTestId}
        autoComplete={autoComplete}
        {...props}
      />
    );
  },
);

Input.displayName = 'Input';

export default Input;
