import React, { useState } from 'react';
import Button from '../../Atoms/Button/Button';
import Heading from '../../Atoms/Heading/Heading';
import Modal from '../Modal/Modal';
import './DeliveryFeeDescription.scss';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';
import Image from '../../Atoms/Image/Image';

interface DeliveryFeeDescriptionProps {
  deliveryFeeDescription: { value: string } | string;
  feeWorkOverlay?: { value: string } | string;
  howFeeWork?: { value: string } | string;
  orderViewModel: any;
  informationIcon?: { value: { src: string } } | string;
  isDeliveryLocator?: boolean;
}
const DeliveryFeeDescription: React.FC<DeliveryFeeDescriptionProps> = ({
  deliveryFeeDescription,
  feeWorkOverlay,
  howFeeWork,
  orderViewModel,
  informationIcon,
  isDeliveryLocator
}) => {
  const [isModal, setisModal] = useState<boolean>(false);
  const howFeeWorkText = () => {
    return (
      <>
        {_isObject(deliveryFeeDescription) ? deliveryFeeDescription.value : deliveryFeeDescription}
        {!_isEmpty(howFeeWork) && (
          <Button
            onClick={() => setisModal(true)}
            testAutoId="how-fee-work"
            className="deliveryText__howFeeWork"
          >
            {_isObject(howFeeWork) ? howFeeWork.value : howFeeWork}
            {informationIcon && (
              <Image
                className="deliveryText__infoIcon"
                testAutoId="how-fee-work-image"
                imageSources={
                  _isObject(informationIcon) ? informationIcon.value?.src : informationIcon
                }
                alt="icon"
              />
            )}
          </Button>
        )}
      </>
    );
  };
  if (orderViewModel.orderType === 'delivery' || isDeliveryLocator) {
    return (
      <div className="deliveryText__comp">
        {!_isEmpty(deliveryFeeDescription) && (
          <Heading
            level="p"
            className="deliveryText__deliveryFeeDescription"
            testAutoId="delivery-fee-banner"
            title={howFeeWorkText()}
          />
        )}

        {isModal && !_isEmpty(feeWorkOverlay) && (
          <Modal
            componentParentClass="deliveryText__comp"
            isShown={isModal}
            title={''}
            onButtonClick={() => setisModal(false)}
            description={_isObject(feeWorkOverlay) ? feeWorkOverlay.value : feeWorkOverlay}
            isClosebtn={true}
          ></Modal>
        )}
      </div>
    );
  } else return null;
};

export default DeliveryFeeDescription;
