import { getDeliveryAddressObject } from '../Utils/componentUtils';
import Deal from '../components/Interfaces/DealInterface';
export const saveRedeemeOffersToCache = (redeemedOffer: any) => {
  window.orderViewModel.redeemedOffers.push(redeemedOffer);

  window.orderViewModel.save();
};

export const checkReddemedOffersFromCache = (deal: Deal) => {
  const isSubwayMerch = (deal.cardType === 'SubwayMerch');
  const hasNoBamkoURL = !deal.bamkoURL;
  return (
    (isSubwayMerch && hasNoBamkoURL) ||
    (window.orderViewModel &&
      window.orderViewModel.redeemedOffers &&
      window.orderViewModel.redeemedOffers.indexOf(deal.id) >= 0)
  );
};

export const saveBannerMessage = (message: string) => {
  window.orderViewModel.showRedirectSuccessBanner = true;
  window.orderViewModel.redirectBannerMessage = message
  window.orderViewModel.save();
}

export const savePromoCodeAppliedFlag = (flag: boolean) => {
  window.orderViewModel.promosAndDeals.isPromoCodeApplied = flag;
  window.orderViewModel.save();
}

export const saveStoreId = (id: string) => {
  window.orderViewModel.store.id = id;
  window.orderViewModel.store.StoreId = id;
  window.orderViewModel.save();
}

export const saveCartQuantity = (quantity: number | string) => {
  window.orderViewModel.currentOrder.cartQuantity = quantity;
  window.orderViewModel.save();
}
export const checkCulture = () => {
  return (
    window.orderViewModel &&
    window.orderViewModel.headerFooterLanguageCode == "fr-CA"
  );
};
export const emptyRedeemedRewards = () => {
  window.orderViewModel.redeemedOffers = [];
  window.orderViewModel.save();
};
export const deliveryDetails = () => {
  return {
    DeliveryAddress:
      window.orderViewModel.orderType == 'delivery' ? getDeliveryAddressObject() : null,
  };
};
export const analyticsTierLevel = (CurrentTierAnalyticTitle: string) => {
  if (window.orderViewModel.hasLoggedIn) {
    window.orderViewModel.Analytics.tierLevel = CurrentTierAnalyticTitle || 'n/a';
    window.orderViewModel.save();
  }
  else {
    window.orderViewModel.Analytics.tierLevel = 'n/a';
    window.orderViewModel.save();
  }
}

export const paypalBannerClosed = (value:boolean) => {
  window.orderViewModel.Payments.paypalBannerClose = value;
  window.orderViewModel.save();
}