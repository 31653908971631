import React, { useEffect, useState } from 'react';
import './Skeleton.scss';
import _isEmpty from 'lodash/isEmpty';

interface SkeletonProps {
  count: number;
  className: string;
  isImage?: boolean;
  isTitle?: boolean;
  isDescription?: boolean;
  isButton?: boolean;
  isButtonText?: boolean;
  titleCount?: number;
  isHeading?: boolean;
  flexContent?: string;
  loadingText?: string;
  announcementDelay?: number;
  ariaLive?: 'polite' | 'assertive' | 'off';
  announceOnLoad?: boolean;
}

/**
 * Skeleton component for all components before data is loaded
 * @param param0.className className for skeleton
 * @param param0.isImage display image skeleton if true
 * @param param0.isTitle display title skeleton if true
 * @param param0.isDescription display description skeleton if true
 * @param param0.isButton display button skeleton if true
 * @param param0.isButtonText display button text skeleton if true
 * @returns Reusable Skeleton component for all components
 */
const Skeleton = ({
  count = 1,
  className,
  isImage = true,
  isTitle = true,
  isDescription = true,
  isButton,
  isButtonText,
  titleCount = 1,
  isHeading = false,
  flexContent = '',
  loadingText = window.loadingValue || 'Loading',
  announcementDelay = 3000,
}: SkeletonProps) => {

  useEffect(() => {
    const ariaLiveElement = document.getElementById('ariaLive');

    if (ariaLiveElement) { ariaLiveElement.innerText = loadingText }

    const intervalId = setInterval(() => {
      if (ariaLiveElement) { ariaLiveElement.innerText = `${loadingText}.` }
    }, announcementDelay);

    return () => {
      clearInterval(intervalId);
      if (ariaLiveElement) { ariaLiveElement.innerText = loadingText + ' done' }
    }
  }, [announcementDelay, loadingText]);

  const SkeletonVariant = `skeleton skeleton--${className}`;
  const flexVariant =
    flexContent !== '' ? `skeleton__container--${flexContent}` : 'skeleton__container';
  const skeletonText = [];
  const skeletonNode = [];

  for (let index = 0; index < titleCount; index++) {
    skeletonText.push(
      <div key={index} className="skeleton__text">
        {isTitle && <div className="title"></div>}
        {isDescription && <div className="description"></div>}
      </div>,
    );
  }
  for (let index = 0; index < count; index++) {
    skeletonNode.push(
      <div key={index} className={SkeletonVariant}>
        <div className="skeleton__content">
          {isImage && <div className="image"></div>}
          {(isTitle || isDescription) && skeletonText}
        </div>

        <div className="skeleton__button">
          {isButton && <div className="button"></div>}
          {isButtonText && <div className="button__text"></div>}
        </div>
      </div>,
    );
  }

  return (
    <div className="skeleton__wrapper" data-testid="skeleton-wrapper">
      {isHeading && (
        <div className="skeleton">
          <h1 className="heading">{''}</h1>
        </div>
      )}
      <div className={flexVariant}>{skeletonNode}</div>
    </div>
  );
};

export default Skeleton;
