import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import Heading from '../../Atoms/Heading/Heading';
import './NutritionInfo.scss';
import _get from 'lodash/get';

interface NutritionInfoProps {
  nutrition: any;
  nutritionTitle: string;
}
interface NutritionObj{
  name:string;
  value:string;
  translations:any[]
}
const NutritionInfo: React.FC<NutritionInfoProps> = ({ nutrition, nutritionTitle }) => {

  return (
    <div className="nutritionInfo__container">
      <Heading level="h2" title={nutritionTitle} testAutoId='nutrition-info-title' />
      <div className="nutritionInfo__table">
        <ul className="nutritionInfo__list">
        {!_isEmpty(nutrition) &&
            nutrition.map((list:NutritionObj,index:number) => {
              return (
                <>
                   <li>
                   <Heading className="nutritionInfo__name" title={_get(list,['translations','0','displayName'],'')} level="span"  testAutoId={`nutrition-info-name-${index}`}/>
                   <Heading className="nutritionInfo__value" title={list.value} level="span" testAutoId={`nutrition-info-value-${index}`}/>
                  </li>
                </>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default NutritionInfo;
