import React, { useEffect, useRef, useState } from 'react';
import Heading from '../../../Atoms/Heading/Heading';
import './OrderDetailsSection.scss';
import Image from '../../../Atoms/Image/Image';
import Button from '../../../Atoms/Button/Button';
import FloatingListItems from '../../../Organisms/FloatingListItems/FloatingListItems';
import useRequest from '../../../../hooks/useRequest';
import _get from 'lodash/get';
import { convertTo12HourFormat, multipleAndCheck, multipleOrCheck, componentRenderCheck } from '../../../../Utils/WindowUtils';
import { CHANGE_ORDER_TYPE_TITLE, CURBSIDE, CURBSIDE_FULLFILLMENT, CURRENTLY_SELECTED_TEXT_EDIT, DELIVERY, DELIVERY_FULLFILLMENT, FULLFILLMENT_EDIT, FULLFILLMENT_TYPE_ORDERDETAILS, GREY, IN_STORE_FULLFILLMENT, IS_EDIT, LOCATOR_ORDERDETAILS, PICKUP, READY_BY_ORDERDETAILS, READY_BY_TIME, STORE_DETAILS_EDIT } from '../../../../constants/App';
import { isEmpty } from 'lodash';
import ErrorModal from '../../../Molecules/CartModal/ErrorModal';
import useStore from '../../../../store/useStore';
import Modal from '../../../Molecules/Modal/Modal';
import classNames from 'classnames';
import { CART_CTA, CURBSIDE_PICKUP_EDIT, DELIVERY_EDIT, IN_STORE_PICKUP_EDIT, LOCATION_EDIT, MODAL_PAGEVIEW, PICK_UP_TIME_EDIT, SWITCH_ORDER_TYPE_CLICK_ACTION, SWITCH_ORDER_TYPE_MODAL, SWITCH_TO_CURBSIDE_PICKUP, SWITCH_TO_DELIVERY, SWITCH_TO_IN_STORE_PICKUP } from '../../../../constants/AnalyticsConstants';

interface OrderDetailsProps {
  orderDetailsData: any;
  dictionaryLables: any;
  orderTypeDetails: any;
  dataSource: string;
  generalProps: any;
  siteName: string;
  cartId: string;
  pickupDateTime: string;
  setCurbsidePopup: (value: boolean) => void;
  saveInStorePickup: (value: string) => void;
}
const OrderDetailsSection: React.FC<OrderDetailsProps> = ({
  orderDetailsData,
  dictionaryLables,
  orderTypeDetails,
  dataSource,
  generalProps,
  siteName,
  cartId,
  pickupDateTime,
  setCurbsidePopup,
  saveInStorePickup
}) => {
  const [showPickupList, setShowPickupList] = useState(false);
  const [showContextLoader, setShowContextLoader] = useState(false);
  const {
    data: getLatestPickupTimeData,
    postRequest: getLatestPickupTimeApi,
    error: getLatestPickupTimeError,
  } = useRequest({ isOnClick: true });
  const {
    data: updatePickupTimeData,
    postRequest: updatePickupTimeApi,
    error: updatePickupTimeError,
  } = useRequest({ isOnClick: true });

  const editBtnRef = useRef<any>(null);

  const {
    curbsideIcon,
    deliveryIcon,
    locationIcon,
    timeIcon,
    pickupIcon,
    deliveryData,
    pickupTime,
    changeOrderType,
    pickUpText,
    curbsideText,
    currentlySelected,
    deliveryText,
  } = orderDetailsData;
  const { fulfillmentType, curbDetails } = orderTypeDetails;
  const {
    switchToInStorePickup,
    readyBy,
    edit,
    orderType,
    curbsidePickup,
    delivery,
    deliveryEstimate,
    deliveryEstimatedMin,
  } = dictionaryLables;
  const { storeMainAddress, cityStateZip } = _get(orderDetailsData, 'storeDetails.address');
  const address = (
    <p>
      <span> {storeMainAddress}</span> <span>{cityStateZip} </span>
    </p>
  );

  const [milliseconds, setmilliseconds] = useState(pickupTime?.refreshInMilliseconds);
  const [pickupTimeList, setPickupTimeList] = useState(pickupTime?.pickupDateTimes);
  const [selectedTime, setSelectedTime] = useState(pickupDateTime);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showFullFillMentEditModal, setShowFullFillMentEditModal] = useState(false);
  const { contextLoader, setContextLoader } = useStore();
  const [erroCtaText, setErroCtaText] = useState('');
  const [isEditAddressClicked, setIsEditAddressClicked] = useState(false);
  const { setAttemptTracker, setAnalyticsProducts, } = useStore();

  useEffect(() => {
    if (multipleAndCheck(selectedTime || pickupDateTime, pickupTime?.pickTime)) {
      const defaultTime = selectedTime || pickupDateTime;
      const nextAvailableTime = pickupTime?.pickTime?.value;
      const date1 = new Date(defaultTime);
      const date2 = new Date(nextAvailableTime);
      if (date1 < date2) {
        setSelectedTime(nextAvailableTime);
      } else if (date1 > date2) {
        setSelectedTime(defaultTime);
      } else {
        setSelectedTime(nextAvailableTime);
      }
    }
  }, [selectedTime, fulfillmentType, pickupDateTime]);

  useEffect(() => {
    const getLatestPickupTime = setInterval(() => {
      if (multipleAndCheck(milliseconds, fulfillmentType !== 'delivery')) {
        const storeDetails = _get(orderDetailsData, ['storeDetails'], [])
        getLatestPickupTimeApi({
          fulfillmentType: fulfillmentType,
          storeDetails: { CurbStart: storeDetails?.curbStart, CurbEnd: storeDetails?.curbEnd, StoreUtcOffset: storeDetails?.storeUtcOffset, LocationId: storeDetails?.locationId },
          datasourceId: dataSource,
          siteName: siteName,
          apiEndpoint: generalProps?.getAvailablePickupTimesApi,
        })
      }
    }, milliseconds)
    return () => {
      clearInterval(getLatestPickupTime)
    }
  }, [milliseconds])

  useEffect(() => {
    if (multipleAndCheck(!isEmpty(getLatestPickupTimeData), !getLatestPickupTimeError)) {
      setPickupTimeList(getLatestPickupTimeData?.pickupDateTimes);
      setSelectedTime(getLatestPickupTimeData?.pickTime?.value);
      setmilliseconds(getLatestPickupTimeData?.refreshInMilliseconds);
    }
    if (!isEmpty(updatePickupTimeData)) {
      setSelectedTime(updatePickupTimeData?.pickupDateTime);
      setShowContextLoader(false);
      setShowPickupList(!showPickupList);
    }
  }, [updatePickupTimeData, getLatestPickupTimeData]);

  useEffect(() => {
    if (
      (!isEmpty(getLatestPickupTimeError) || !isEmpty(updatePickupTimeError)) &&
      !showErrorModal
    ) {
      let erroCtaText = '';
      setShowPickupList(false);
      setShowErrorModal(!showErrorModal);

      if (!isEmpty(getLatestPickupTimeError)) {
        erroCtaText = dictionaryLables?.backToOrder;
      }
      if (!isEmpty(updatePickupTimeError)) {
        erroCtaText = window.okText;
      }

      setErroCtaText(erroCtaText);
    }
  }, [getLatestPickupTimeError, updatePickupTimeError]);

  const handleSelectedPickupTime = (selectedTime: 'string', analyticsText: string) => {
    setAttemptTracker([{ name: CART_CTA }]);
    setAnalyticsProducts({ clickActionName: analyticsText });
    if (!showContextLoader) {
      setShowContextLoader(true);
      updatePickupTimeApi({
        apiEndpoint: generalProps.updateExpectedReadyTime,
        cartId: cartId,
        setPickupTime: selectedTime,
        storeUtc: _get(orderDetailsData, ['storeDetails', 'utcOffset'], ''),
      });
    } else {
      return false;
    }
  };

  const handleEdit = (itemName: string) => {
    setAttemptTracker([{ name: CART_CTA }]);
    switch (itemName) {
      case READY_BY_TIME:
        setAnalyticsProducts({ clickActionName: PICK_UP_TIME_EDIT });
        setShowPickupList(true);
        break;
      case FULLFILLMENT_EDIT:
        fullfillmentEditAnalytics();
        setShowFullFillMentEditModal(true);
        setTimeout(() => {
          setAttemptTracker([{ name: MODAL_PAGEVIEW }]);
        }, 50);
        break;
      case STORE_DETAILS_EDIT:
        setAnalyticsProducts({ clickActionName: LOCATION_EDIT });
        setIsEditAddressClicked(true);
        if (fulfillmentType === DELIVERY) {
          setTimeout(() => {
            window.location.href = `${window.subwayScript.navigation.getDeliveryLocatorUrl()}${IS_EDIT}`;
          }, 50);
        }
        else {
          setTimeout(() => {
            window.location.href = `${window.subwayScript.navigation.getLocatorUrl()}${IS_EDIT}`;
          }, 50);
        }
    }
  };

  const fullfillmentEditAnalytics = () => {
    if (fulfillmentType === PICKUP) {
      setAnalyticsProducts({
        clickActionName: IN_STORE_PICKUP_EDIT,
        virtualName: SWITCH_ORDER_TYPE_MODAL,
      });
    }
    if (fulfillmentType === CURBSIDE) {
      setAnalyticsProducts({
        clickActionName: CURBSIDE_PICKUP_EDIT,
        virtualName: SWITCH_ORDER_TYPE_MODAL,
      });
    }
    if (fulfillmentType === DELIVERY) {
      setAnalyticsProducts({
        clickActionName: DELIVERY_EDIT,
        virtualName: SWITCH_ORDER_TYPE_MODAL,
      });
    }
  }

  const handleSetShowPickupList = () => {
    setShowPickupList(false);
    setAnalyticsProducts({
      clickActionName: '',
    });
  };

  const fullfillmentModalCtaClick = (testAutoID: string) => {
    setAttemptTracker([{ name: SWITCH_ORDER_TYPE_CLICK_ACTION }]);
    if (testAutoID === DELIVERY_FULLFILLMENT) {
      setAnalyticsProducts({
        clickActionName: SWITCH_TO_DELIVERY,
        componentTitle: SWITCH_ORDER_TYPE_MODAL
      });
      setContextLoader({
        buttonId: testAutoID,
        isLoading: true,
      });
      setTimeout(() => {
        window.location.href = `${window.subwayScript.navigation.getDeliveryLocatorUrl()}${IS_EDIT}`;
      }, 500);
    }

    if (testAutoID === CURBSIDE_FULLFILLMENT) {
      setAnalyticsProducts({
        clickActionName: SWITCH_TO_CURBSIDE_PICKUP,
        componentTitle: SWITCH_ORDER_TYPE_MODAL
      });
      setCurbsidePopup(true);
    }
    if (testAutoID === IN_STORE_FULLFILLMENT) {
      setAnalyticsProducts({
        clickActionName: SWITCH_TO_IN_STORE_PICKUP,
        componentTitle: SWITCH_ORDER_TYPE_MODAL
      });
      saveInStorePickup(testAutoID);
    }
  };

  useEffect(() => {
    if (fulfillmentType != DELIVERY) {
      setShowFullFillMentEditModal(false);
      setAnalyticsProducts({
        virtualName: '',
        componentTitle: '',
      });
      setPickupTimeList(pickupTime?.pickupDateTimes);
    }
  }, [fulfillmentType]);

  const getOrderDetails = (
    fulfillmentType: string,
    selectedTime: string | number | Date,
    deliveryData: { latestDeliveryTimeRangeMin: any; latestDeliveryTimeRangeMax: any },
  ) => {
    let orderTypeIcon, ordertypeText, readyByText;
    switch (fulfillmentType) {
      case 'pickup':
        orderTypeIcon = pickupIcon;
        ordertypeText = switchToInStorePickup;
        readyByText = readyBy + ' ' + convertTo12HourFormat(selectedTime);
        break;

      case 'curbside':
        orderTypeIcon = curbsideIcon;
        ordertypeText = curbsidePickup;
        readyByText = readyBy + ' ' + convertTo12HourFormat(selectedTime);
        break;

      default:
        orderTypeIcon = deliveryIcon;
        ordertypeText = delivery;
        readyByText = `${deliveryEstimate}: ${deliveryData?.latestDeliveryTimeRangeMin}-${deliveryData?.latestDeliveryTimeRangeMax} ${deliveryEstimatedMin}`;
        break;
    }

    return { orderTypeIcon, ordertypeText, readyByText };
  }

  const { orderTypeIcon, ordertypeText, readyByText } = getOrderDetails(fulfillmentType, selectedTime, deliveryData);

  const getImageByVehicleName = (name: string) => {
    return curbDetails?.vehicleTypes ? curbDetails.vehicleTypes?.find((vehicle: { name: string }) => vehicle.name === name) : null;
  };
  const getOrderDetail = (icon: string, text: JSX.Element | string, itemName: string, ariaLabelId: string) => {
    return (
      <div className={
        classNames('OrderDetails__sections ', {
          'OrderDetails__sections--disabled': (
            multipleAndCheck(itemName === READY_BY_TIME, showPickupList) ||
            multipleAndCheck(itemName === FULLFILLMENT_EDIT, showFullFillMentEditModal)
          ),
          'OrderDetails__sections--full-disabled': multipleAndCheck(itemName === STORE_DETAILS_EDIT, isEditAddressClicked)
        })
      }>
        <Image
          className="OrderDetails__logo"
          testAutoId="order-delivery-extra-image"
          imageSources={icon}
        />
        <Heading level="h2" id={ariaLabelId} className="OrderDetails__text" testAutoId='OrderDetails--text' title={text} />
        {
          !multipleAndCheck(itemName == READY_BY_TIME, fulfillmentType === DELIVERY) && (
            <div className="" ref={editBtnRef}    >
              <Button
                testAutoId={`OrderDetails--editBtn-${itemName}`}
                className="OrderDetails__EditBtn"
                onClick={() => handleEdit(itemName)}
                aria-labelledby={ariaLabelId}
              >
                {edit}
              </Button>
            </div>
          )
        }
        {itemName == READY_BY_TIME && showPickupList && (
          <FloatingListItems
            allList={pickupTimeList}
            dictionaryLabels={dictionaryLables}
            handleSelectedPickupTime={handleSelectedPickupTime}
            showContextLoader={showContextLoader}
            handleSetShowPickupList={handleSetShowPickupList}
            editBtnRef={editBtnRef}
            selectedTime={selectedTime}
            isShowPickupList={showPickupList}
          />
        )}
      </div>
    );
  };

  const getButtonAndLabel = (ctaText: string, fullFillType: string, testAutoID: string) => {
    return <>
      <Button testAutoId={testAutoID}
        isDisabled={fulfillmentType === fullFillType}
        aria-disabled={fulfillmentType === fullFillType}
        onClick={() => { fullfillmentModalCtaClick(testAutoID) }}
        className={`secondary__btn edit-fullfillment-modal--cta-${fullFillType}`}>{ctaText}
      </Button>
      {
        componentRenderCheck(fulfillmentType === fullFillType,
          <Heading title={currentlySelected}
            level='p'
            className={`edit-fullfillment-modal--selected-text-${fullFillType}`}
            testAutoId={CURRENTLY_SELECTED_TEXT_EDIT} />)
      }
    </>
  }
  return (
    <>
      {showFullFillMentEditModal && (
        <Modal
          componentParentClass="edit-fullfillment-modal"
          isShown={showFullFillMentEditModal}
          isClosebtn={true}
          onButtonClick={() => {
            setShowFullFillMentEditModal(false);            
            setAnalyticsProducts({
              virtualName: '',
            });
          }}
        >
          <Heading id="title__labelledby" title={changeOrderType}
            className='edit-fullfillment-modal--title'
            testAutoId={CHANGE_ORDER_TYPE_TITLE} />
          {getButtonAndLabel(pickUpText, PICKUP, IN_STORE_FULLFILLMENT)}
          {getButtonAndLabel(curbsideText, CURBSIDE, CURBSIDE_FULLFILLMENT)}
          {getButtonAndLabel(deliveryText, DELIVERY, DELIVERY_FULLFILLMENT)}
        </Modal>
      )}
      <div className="OrderDetails__wrapper">
        <Heading
          level="h1"
          className="OrderDetails__title"
          testAutoId="OrderDetails--title"
          title={orderType}
        />
        <div className="OrderDetails__mainContent">
          {getOrderDetail(orderTypeIcon, ordertypeText, FULLFILLMENT_EDIT, FULLFILLMENT_TYPE_ORDERDETAILS)}
          {getOrderDetail(locationIcon, address, STORE_DETAILS_EDIT, LOCATOR_ORDERDETAILS)}
          {getOrderDetail(timeIcon, readyByText, READY_BY_TIME, READY_BY_ORDERDETAILS)}
        </div>

        {componentRenderCheck(
          multipleAndCheck(curbDetails && fulfillmentType === CURBSIDE),
          <div className="vehicle__details">
            <Image
              className="vehicle__details--icon"
              imageSources={getImageByVehicleName(_get(curbDetails, 'vehicleType'))?.image}
            />
            <div className="vehicle__details--text">
              <Heading
                level="p"
                title={`${_get(curbDetails, 'vehicleColor')} ${_get(curbDetails, 'vehicleType')}`}
              />
            </div>
          </div>,
        )}
      </div>
      {showErrorModal &&
        multipleOrCheck(!isEmpty(getLatestPickupTimeError), !isEmpty(updatePickupTimeError)) && (
          <ErrorModal
            error={getLatestPickupTimeError || updatePickupTimeError}
            okText={erroCtaText}
            value={'cart-order-details'}
          />
        )}
    </>
  );
};

export default OrderDetailsSection;
