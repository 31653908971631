import React, {useEffect } from 'react';
import Carousel from 'nuka-carousel';
import './CustomSlider.scss';
import UseWindowWidth from '../../../hooks/useWindowWidth';
import Controls from './ButtonControls/Controls';
import { calculateExtraSlide, calculateSlidesToShow } from '../../../Utils/componentUtils';
import {
  PreviousButtonText,
  NextButtonText,
} from '../../../constants/App';
import { multipleAndCheck,ternaryCheck,componentRenderCheck } from '../../../Utils/WindowUtils';

type CellAlign = 'center' | 'right' | 'left';

interface ContentSliderProps {
  isWithoutControls?: boolean;
  slideCount?: number;
  isDragging?: boolean;
  children?: React.ReactNode;
  slides: number;
  className?: string;
  customBackBtnText?: string;
  customNextBtnText?: string;
  customDoneBtnText?: string;
  customOnclickHandler?: () => void;
  isQuickAddOns?: boolean;
  noExtraSlideView?: boolean;
  oneViewPerSlide?: boolean;
  getCurrentSlideIndex?: (params: number) => any;
  isTablet?: boolean;
  cellAlignment?: CellAlign;
  isFavoriteOrBestseller?: boolean;
  fromModalCarousel?: boolean;
  sliderRef?: React.RefObject<HTMLDivElement>
}

/**
 * Reusable custom slider component
 * @param param0.isWithoutControls boolean to show or hide controls
 * @param param0.slideCount number of slides to show
 * @param param0.isDragging boolean to enable or disable dragging
 * @param param0.children children to be rendered
 * @param param0.className custom className to be rendered for Carousel
 * @param param0.mobileSlideCount Slide count for mobile view.
 * @param param0.isFavoriteOrBestseller Favorite Or Bestseller Slide count for mobile view.
 * @returns reusable custom slider component
 */
const CustomSlider: React.FC<ContentSliderProps> = ({
  children,
  isWithoutControls,
  isDragging = true,
  slideCount = 4,
  slides,
  className,
  customBackBtnText,
  customNextBtnText,
  customDoneBtnText,
  customOnclickHandler,
  isQuickAddOns = false,
  noExtraSlideView = false,
  oneViewPerSlide = false,
  getCurrentSlideIndex,
  isTablet = false,
  isFavoriteOrBestseller = false,
  cellAlignment = 'left',
  fromModalCarousel = false,
  sliderRef
  
}) => {
  const windowWidth = UseWindowWidth();
  const extraSlide = calculateExtraSlide(isQuickAddOns, windowWidth, noExtraSlideView,fromModalCarousel);
  const slidesToShow = calculateSlidesToShow(
    isQuickAddOns,
    isTablet,
    slideCount,
    extraSlide,
    windowWidth,
    oneViewPerSlide,
    isFavoriteOrBestseller,
    fromModalCarousel
  );
  const [slideIndex, setSlideIndex] = React.useState(0);

  const handleNextClick = () => {
  if (slideIndex < slides - slidesToShow - extraSlide) {
    setSlideIndex(slideIndex + 1);
  }
  };
  const handlePrevClick = () => {
  if (slideIndex > 0) {
    setSlideIndex(slideIndex - 1);
  }
  };
  const handleLastClick = () => {
  handleNextClick();
    if (slideIndex === slides - 1 && customOnclickHandler) {
    customOnclickHandler();
  }
  };

  const getPrevClassname = () =>{
    return ternaryCheck(slideIndex === 0,'button__disable','' );
  }
  const getNextClassname = () => {
    return ternaryCheck(slideIndex >= slides - slidesToShow - extraSlide,'button__disable','' );
  }

  const prevBtn1 = ternaryCheck(!!className,'',PreviousButtonText);

  const prevBtn2 = ternaryCheck(!!className,customBackBtnText,PreviousButtonText);

  const nextBtn1 = ternaryCheck(!!className,customDoneBtnText,NextButtonText);

  const nextBtn2 = ternaryCheck(!!className,customNextBtnText,NextButtonText);

  const isClassName = ternaryCheck(!!className,'false','true');

  const extractedResult = ternaryCheck(slideIndex >= slides - slidesToShow - extraSlide,isClassName,'false');

  useEffect(() => {
    const parentElement = document.querySelector(".slider-container");
  if (parentElement) {
    const childDivs = parentElement.querySelectorAll('div');
    if (multipleAndCheck(childDivs,childDivs.length > 0)) {
      const childDivArray = Array.from(childDivs);
      parentElement.removeChild(childDivArray[0]);
    }
  }
  }, []);

  return (
    <>
      <div
        data-testid="custom-slider"
        className={ternaryCheck(!!className,`contentSlider ${className}`,'contentSlider')}
        ref={sliderRef}
      >
        <Carousel
          cellAlign={cellAlignment}
          slideIndex={slideIndex}
          withoutControls={isWithoutControls}
          enableKeyboardControls={true}
          dragging={isDragging}
          slidesToShow={slidesToShow}
          beforeSlide={(currentSlideIndex, nextSlideIndex) => {
            setSlideIndex(nextSlideIndex);
            getCurrentSlideIndex && getCurrentSlideIndex(nextSlideIndex);
          }}
        >
          {children}
        </Carousel>
        {componentRenderCheck(slides > Math.round(slidesToShow),(
          <div className="carousel-buttons">
            <Controls
              className={`prev-button ${getPrevClassname()}`}
              btnName={ternaryCheck(slideIndex === 0,prevBtn1,prevBtn2)}
              ariaLabel={ternaryCheck(slideIndex === 0,'true','false')}
              slideIndex={slideIndex}
              onClick={handlePrevClick}
              buttonProps={ternaryCheck(!!className && slideIndex === 0,null,customBackBtnText)}
            />
            <Controls
              className={`next-button ${getNextClassname()}`}
              btnName={ternaryCheck(slideIndex >= slides - slidesToShow - extraSlide, nextBtn1, nextBtn2)}
              ariaLabel={extractedResult}
              slideIndex={slideIndex}
              onClick={ternaryCheck(!!className,handleLastClick,handleNextClick)}
              buttonProps={
                ternaryCheck(!!className && slideIndex === slides - 1,customDoneBtnText,customNextBtnText)}
            />
          </div>
        ))}
      </div>
    </> 
  );
};
export default CustomSlider;
