import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { axiosAPICall } from '../Utils/AxiosUtils';
import _omit from 'lodash/omit';
import { AxiosError } from 'axios';
import _isEmpty from 'lodash/isEmpty';
import useStore from '../store/useStore';

/**
 * Reusable hook to call GET | POST API
 * @param param0.params params to be passed to the api
 * @param param0.isMutation if the api is a mutation or query, true=mutation|POST, false=query|GET
 * @param param0.key key to be used for the query
 * @param param0.isOnClick if the api call is on click somewhere in the component
 * @returns {object} query or mutation object
 */
const useRequest = ({ params = {}, isMutation = true, key = '', isOnClick = false }) => {
  const [payload] = useState(params);
  const { setContextLoader, contextLoader } = useStore();
  const [currentApiEndpoint, setCurrentApiEndpoint] = useState('');

  const callMutation = async (data: any) => {
    setCurrentApiEndpoint(data.apiEndpoint);
    const payloads = _omit(data, 'apiEndpoint');
    if (payloads.isGet) {
      return axiosAPICall
        .get(data.apiEndpoint, { params: _omit(payloads, 'isGet') })
        .then(response => response.data);
    }
    return axiosAPICall.post(data.apiEndpoint, payloads).then(response => response.data);
  };

  const postQuery = useMutation(callMutation, {
    onError: (error: AxiosError) => error,
  });

  useEffect(() => {
    if (!isOnClick && !_isEmpty(payload) && isMutation) {
      const { mutateAsync } = postQuery;
      mutateAsync(payload);
    }
  }, []);

  useEffect(() => {
    setContextLoader({
      ...contextLoader,
      isLoading: postQuery.isLoading,
    });
  }, [postQuery.isLoading]);

  return {
    data: postQuery.data,
    isLoading: postQuery.isLoading,
    error: postQuery.error as any,
    postRequest: (param: any) => postQuery.mutateAsync(param),
    getRequest: (param: any) => postQuery.mutateAsync({ ...param, isGet: true }),
    currentApiEndpoint: currentApiEndpoint,
  };
};

export default useRequest;
