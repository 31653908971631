import React, { useState, useRef, useEffect } from 'react';
import Image from '../../../Atoms/Image/Image';
import Heading from '../../../Atoms/Heading/Heading';
import Button from '../../../Atoms/Button/Button';
import SvgIconClose from '../../../../assets/RemoteOrder/icons/Cross.svg';
import GiftBox from '../../../../assets/RemoteOrder/img/Giftbox.png'
import useStore from '../../../../store/useStore';
import './YellowSticker.scss';
import { navigateToRewardsActivity } from '../../../../Utils/WindowUtils';
import {
  VIEW_MY_REWARDS,
  YELLOW_STICKER,
  CART_CLICK_ACTION,
} from '../../../../constants/AnalyticsConstants';
import { addFocusListener } from '../../../../Utils/componentUtils';

interface YellowStickerProps {
  viewRewards: string;
  sweetGotRewards: string;
}
const YellowSticker: React.FC<YellowStickerProps> = ({ viewRewards, sweetGotRewards }) => {
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const { setAttemptTracker, setAnalyticsProducts } = useStore();
  const buttonRef = useRef<HTMLDivElement>(null); 
  const firstFocusableElementRef = useRef<any>(null);
  const lastFocusableElementRef = useRef<any>(null);
  useEffect(() => {
    addFocusListener(buttonRef,firstFocusableElementRef,lastFocusableElementRef);
    const stickTo = document.getElementById('stick-to') as HTMLElement;
    const headerSticky = stickTo?.querySelector('.header-sticky');
    const componentFooter = stickTo?.querySelector('.component_footer');
    const rootReactElements = document.getElementById('root-react-pr') as HTMLElement;
    const navBarStickyHeader = rootReactElements?.querySelector('.navbar.sticky-header');
    const orderManagementContainer = document.getElementById('switchbodymaincontent') as HTMLElement;
    const orderManagement = orderManagementContainer?.querySelector('.order-management');
    const globalFooterContainer = rootReactElements?.querySelector('.global-footer__container');
    const horisocialFooterContainer = rootReactElements?.querySelector('.horisocial-footer__container');
    const legalCopyFooter = rootReactElements?.querySelector('.legal-copy__footer');
  
    headerSticky?.setAttribute('aria-hidden', isExpand ? 'true' : 'false');
    navBarStickyHeader?.setAttribute('aria-hidden', isExpand ? 'true' : 'false');
    orderManagement?.setAttribute('aria-hidden', isExpand ? 'true' : 'false');
    globalFooterContainer?.setAttribute('aria-hidden', isExpand ? 'true' : 'false');
    horisocialFooterContainer?.setAttribute('aria-hidden', isExpand ? 'true' : 'false');
    legalCopyFooter?.setAttribute('aria-hidden', isExpand ? 'true' : 'false');
    componentFooter?.setAttribute('aria-hidden', isExpand ? 'true' : 'false');    

    return () => {     
        headerSticky?.removeAttribute('aria-hidden');
        navBarStickyHeader?.removeAttribute('aria-hidden');
        orderManagement?.removeAttribute('aria-hidden');
        globalFooterContainer?.removeAttribute('aria-hidden');
        horisocialFooterContainer?.removeAttribute('aria-hidden');
        legalCopyFooter?.removeAttribute('aria-hidden');
        componentFooter?.removeAttribute('aria-hidden');
    };
  }, [isExpand]);

  const onClickGiftBox = () => {
    if (!isExpand) {
      setAnalyticsProducts({
        componentTitle: YELLOW_STICKER,
      });
      setAttemptTracker([{ name: CART_CLICK_ACTION }]);
    }
    setIsExpand(!isExpand);
  };
  const onClickViewRewards = () => {
    setAnalyticsProducts({
      viewRewards: 1,
      clickActionName: VIEW_MY_REWARDS,
      componentTitle: YELLOW_STICKER,
    });
    setAttemptTracker([{ name: CART_CLICK_ACTION }]);
    setTimeout(() => {
      navigateToRewardsActivity();
    }, 1000);
  };
  return (
    <div className={`rewards__sticker__container ${isExpand ? 'modalPopup' : ''}`}>
      <div
        role={!isExpand ? "button" : ""}
        tabIndex={0}
        data-testauto-id={'reward-giftbox'}
        aria-label={viewRewards}
        onClick={onClickGiftBox}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            onClickGiftBox();
          }
        }}
        className={`rewards__sticker ${isExpand ? 'rewards__sticker__expand' : ''}`}
        ref={buttonRef}
      >
        {isExpand && (
          <Button
            aria-label="close"
            testAutoId={'reward-collapse'}
            className="rewards__sticker__close"
            onClick={onClickGiftBox}
            icon={<SvgIconClose />}
          />
        )}
        <Image className="rewards__sticker__gift__box" imageSources={GiftBox} alt={viewRewards} />
        {isExpand && <Heading className="rewards__sticker__heading" level="h5" title={sweetGotRewards} />}
        {isExpand && (
          <Button testAutoId={'view-rewards'} onClick={onClickViewRewards} className="rewards__sticker__view__rewards">
            {viewRewards}
          </Button>
        )}
      </div>
    </div>
  );
};

export default YellowSticker;
