import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { GREEN, GREY, WHITE } from '../../../constants/App';
import { LinkProps } from '../../../Interfaces/LinkProps';
import DancingDotsGreen from '../../../../src/assets/RemoteOrder/confettie/DancingDotsGreen.json';
import DancingDotsGrey from '../../../../src/assets/RemoteOrder/confettie/DancingDotsGrey.json';
import DancingDotsWhite from '../../../../src/assets/RemoteOrder/confettie/DancingDotsWhite.json';
import useStore from '../../../store/useStore';
import Lottie from 'lottie-react';
import { disableInteractiveElements } from '../../../Utils/WindowUtils';
const _global = (window || global) as any;

/**
 * Link component
 * @param param0.text text
 * @param param0.className custom class name
 * @returns react link component
 */
const Link: React.FC<LinkProps> = ({ href = '', text, className, style, onClick, analyticsData,testId,testAutoId,...props }) => {
  const onClickHandler = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    onClick && onClick(e);
    if (!isEmpty(analyticsData)) {
      _global.analytics.dashboardNotificationClick(
        analyticsData.sectionName,
        analyticsData.cta_Title.toLowerCase(),
      );
    }
  };
  const { contextLoader, setContextLoader } = useStore();
  const getAnimationData = (animationColor: 'white' | 'grey' | 'green') => {
    switch (animationColor) {
      case GREEN:
        return DancingDotsGreen;
      case GREY:
        return DancingDotsGrey;
      case WHITE:
      default:
        return DancingDotsWhite;
    }
  };
  const isLottieLoading = contextLoader?.isLoading && contextLoader.buttonId === testAutoId;

  useEffect(() => {
    if (isLottieLoading) {
      if (!document.body.classList.contains('disableInteraction')) {
        disableInteractiveElements();
      }
    }

    return () => {
      if (document.body.classList.contains('disableInteraction')) {
        disableInteractiveElements();
          setContextLoader({animationColor: WHITE, buttonId: '', isLoading: false});
      }
    };
  }, [isLottieLoading]);

  return (
    <a
      href={href}
      onClick={e => {
        onClickHandler(e);
      }}
      className={className}
      style={style}
      data-testid={testId}
      data-testauto-id={testAutoId}
      {...props}
    >
       {isLottieLoading ? (
        <Lottie
          className={`in-context-loader`}
          animationData={getAnimationData(contextLoader.animationColor)}
        />
      ) : (
        <>
         {text}
        </>
      )}
    </a>
  );
};

export default Link;