import React, { useEffect, useRef, useState } from 'react';
import Button from '../../Atoms/Button/Button';
import Heading from '../../Atoms/Heading/Heading';
import CartItems from './CartItems/CartItems';
import SvgIconClose from '../../../assets/RemoteOrder/icons/Cross.svg';
import SvgIconNext from '../../../assets/RemoteOrder/icons/Next.svg';
import './OrderManagement.scss';
import useRequest from '../../../hooks/useRequest';
import Skeleton from '../Skeleton/Skeleton';
import { Items, OrderManagementProps } from '../../Interfaces/OrderManagementInterface';
import __pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import ErrorPopup from './ErrorModal/ErrorPopup';
import ErrorModal from '../../Molecules/CartModal/ErrorModal';
import InfoModal from '../../Molecules/CartModal/InfoModal';
import OrderSummary from './OrderSummary/OrderSummary';
import Image from '../../Atoms/Image/Image';
import deliveryIcon from '../../../assets/RemoteOrder/img/delivery.png';
import _isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import UseWindowWidth from '../../../hooks/useWindowWidth';
import UpSellOverlay from '../../Modal/UpSellOverlay';
import VehicleSelection from './VehicleSelection/VehicleSelection';
import QuickAddOn from './QuickAddOn/QuickAddOn';
import OrderDetailsSection from './Order Details/OrderDetailsSection';
import {
  bannerHeight,
  prepareComboData,
  prepareErrorMessage,
  toastBannerAnalytics,
} from '../../../Utils/componentUtils';
import {
  CLEAR_CART,
  ORDER_SOMETHING_ELSE,
  REMOVE_ITEMS_FROM_CART,
  DELIVERY,
  EDIT,
  APPLY,
  REMOVE,
  REMOVE_COMBO_ITEMS,
  QUICK_ADD_ONS,
  CART,
  ERRORCODE_3007,
  CULTURE_CODE_CA,
  ORDER_TYPE_CURBSIDE,
  OK_CTA,
  CART_QUICKADDONS_SOURCE,
  CART_INCREMENT_SOURCE,
  PR,
  cultureLanguage,
  PICKUP,
  GREEN,
  WHITE,
} from '../../../constants/App';
import _get from 'lodash/get';
import { productString } from '../../Analytics/AnalyticsNew';
import useStore from '../../../store/useStore';
import EmptyCart from './EmptyCart/EmptyCart';
import {
  ADD_TO_BAG,
  CARTCONTINUE,
  CART_CTA,
  CART_PAGEVIEW,
  CART_PROMO_CODE_APPY,
  CLEARMYBAG,
  CONTINUE,
  CONTINUEASGUEST,
  CONTINUETOPAYMENT,
  CURBSIDE,
  DECREASE_QUANTITY,
  EMPTYBAG,
  EMPTY_CART_PAGEVIEW,
  GUESTCHECKOUT,
  INCREASE_QUANTITY,
  MODAL_PAGEVIEW,
  ORDERSOMETHINGELSE,
  OUT_OF_STOCK_MODAL,
  PROMO_CODE_APPLY,
  REMOVEITEMFROMCART,
  REMOVE_COMBO,
  REMOVE_OFFER_REWARD,
  REMOVE_FROM_CART,
  REMOVE_MIAM,
  UPDATE_CART,
  UPSELL_MODAL,
  VEHICLE_MODAL,
  CURBSIDE_POPUP,
  REWARD_REMOVED,
  MAKE_IT_A_COMBO,
  REMOVE_FROM_BAG,
  KEEP_SHOPING,
} from '../../../constants/AnalyticsConstants';
import { getProductList, getComboClickActionName } from '../../../Utils/analyticsUtils';
import { emptyRedeemedRewards } from '../../../Utils/OrderViewModelUtils';
import YellowSticker from './YellowSticker/YellowSticker';
import { getUtcTime, reactEngageApptentiveEvent } from '../../../Utils/pageUtils';
import Input from '../../Atoms/InputBox/Input';
import { ternaryCheck, componentRenderCheck, multipleAndCheck } from '../../../Utils/WindowUtils';
import { KEEP_SHOPPING_CTA, LOGGEDOUT_CONTINUE, ORDER_CHECKOUT_LOGIN } from '../../../constants/ContextConstants';
/**
 * Order Management Component
 * @param param0.apiEndpoint - api endpoint
 * @param param0.orderViewModel - orderViewModal global object
 * @param param0.dataSource - data source id
 * @param param0.siteName - site name, eg: 'remoteorder-US'
 * @returns Order Management Template to show cart items and order summary
 */
const MAX_LENGTH = 144;

const OrderManagement: React.FC<OrderManagementProps> = ({
  apiEndpoint,
  orderViewModel,
  dataSource,
  siteName,
  okText,
  subwayScript,
}) => {
  const {
    data,
    isLoading,
    postRequest,
    error: apiErrors,
    getRequest,
  } = useRequest({
    params: {
      apiEndpoint: apiEndpoint.value,
      StoreId: orderViewModel.store.id,
      CartId: orderViewModel.currentOrder.cartId,
      IsQuickaddon: true,
      DatasourceId: dataSource,
      sitename: siteName,
      userLocalTime: getUtcTime(),
      CurrentTierLevel: orderViewModel.Analytics.tierLevel,
    },
  });
  const showSticky = ternaryCheck(UseWindowWidth() < 1024, true, false);
  const [showProp, setShowProp] = useState<boolean>(false);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [items, setItems] = useState<Items[]>([]);
  const [itemsData, setItemsData] = useState<any>({});
  const [dictionaryLables, setDictionaryLables] = useState<any>({});
  const [errorDictionary, setErrorDictionary] = useState<any>({});
  const [cartId, setCartId] = useState<string>('');
  const [flags, setFlags] = useState<any>({});
  const [orderTypeDetails, setOrderTypeDetails] = useState<any>({});
  const [orderDetailsData, setOrderDetailsData] = useState<any>({});
  const [error, setError] = useState<any>(null);
  const [isRemoveItem, setIsRemoveItem] = useState<boolean>(false);
  const [copyCart, setCopyCart] = useState<boolean>(false);
  const [cartProducts, setCartProducts] = useState<any>([]);
  const [removeItemData, setRemoveItemData] = useState<any>({});
  const [unavailableComoboWarning, setUnavailableComoboWarning] = useState<any>([]);
  const [generalProps, setGeneralProps] = useState<any>(null);
  const [errors, setErrors] = useState<any>(null);
  const [itemToRemove, setItemToRemove] = useState<string>('');
  const [isRefetch, setIsRefetch] = useState<boolean>(false);
  const [handleRemove, setHandleRemove] = useState<boolean>(false);
  const [multipleItemsToRemove, setMultipleItemsToRemove] = useState<any>([]);
  const [summaryData, setSummaryData] = useState<any>(null);
  const [showYellowSticker, setShowYellowSticker] = useState<boolean>(false);
  const [quickAddOns, setQuickAddOns] = useState<any>(null);
  const [isQuickAddOnAdded, setIsQuickAddOnAdded] = useState<boolean>(false);
  const [instruction, setInstruction] = useState<string>('');
  const [clearCart, setClearCart] = useState<boolean>(false);
  const [invalidPromoData, setInvalidPromoData] = useState<any>({});
  const [notOfferedData, setNotOfferedData] = useState<any>({});
  const [comboMeal, setComboMeal] = useState<any>({});
  const [offerIdData, setOfferIdData] = useState<string>('');
  const [emptyCartInfo, setEmptyCartInfo] = useState<any>({});
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isUpSellModal, setIsUpSellModal] = useState<boolean>(false);
  const [combos, setCombos] = useState<any>([]);
  const [pdpLink, setPDPLink] = useState<string>('');
  const [curbsidePopup, setCurbsidePopup] = useState<boolean>(false);
  const [deliverySelection, setDeliverySelection] = useState<boolean>(false);
  const [summaryLabels, setSummaryLabels] = useState<any>({});
  const [removalType, setRemovalType] = useState<string>('');
  const [isrefetchOM, setIsrefetchOM] = useState<boolean>(false);
  const [isRefetchOnContinue, setIsRefetchOnContinue] = useState<boolean>(false);
  const [selectedVehicle, setSelectedVehicle] = useState<string>('');
  const [selectedColor, setSelectedColor] = useState<string>('');
  const [extraBannerText, setExtraBannerText] = useState<string>('');
  const {
    setAttemptTracker,
    setAnalyticsProducts,
    setSelectedCard,
    setTotalCards,
    setBannerHeightCal,
    setContextLoader
  } = useStore();
  const isLoggedIn = useStore(state => state.isLoggedIn);
  const [itemsSummary, setItemsSummary] = useState<any>({});
  const [productStringData, setProductStringData] = useState<any>({});
  const [quickAddonsAnalytics, setQuickAddonsAnalytics] = useState<any>({});
  const [decreaseQuantity, setDecreaseQuantity] = useState<boolean>(false);
  const [vanityCode, setVanityCode] = useState<string>('');
  const [checkoutContinue, setCheckoutContinue] = useState<string>('');
  const [isVanityCode, setIsVanityCode] = useState<boolean>(false);
  const [utensilsData, setUtensilsData] = useState<any>([]);
  const [utensilsDataUpdated, setUtensilsDataUpdated] = useState<any>([]);
  const utensilStorage = JSON.parse(sessionStorage.getItem('utensilObject') ?? '[]');
  const [finalInstructions, setFinalInstructions] = useState<string>('');
  const { setCartQuantity } = useStore();
  const utensilsFlag = (window as any).client?.variation((window as any).FF_ENABLE_UTENSILS, false);
  const header = document.querySelector('.sticky-header') as HTMLElement;
  const windowSize = UseWindowWidth()
  const [pickupDateTime, setPickupDateTime] = useState('')
  useEffect(() => {
    if (header) {
      header.style.visibility = showSticky ? 'hidden' : 'initial';
    }
  }, [showSticky, header]);

  useEffect(() => {
    if (data) {
      if (data.itemsSummary) {
        const {
          itemsSummary: items_Summary,
          dictionaryLables: dictionary_Lables,
          orderTypeDetails: orderType_Details,
          orderDetailsData: orderDetailsData,
          cartId: cart_Id,
          error: error_Details,
          generalProps: general_Props,
          quickAddOns: quick_AddOns,
          isYellowSticker,
          vehicleColor,
          vehicleType
        } = data;
        const {
          itemData,
          items: items_Detail,
          errorDictionary: error_Dictionary,
          utensilsData: utensils_Data,
        } = items_Summary;
        const item_flags = __pick(items_Summary, [
          'isIngredientWarning',
          'hasAllOutStockProducts',
          'hasSomeOutStockProducts',
          'hasAllOutStockcombo',
          'outStockProducts',
          'isIngredientcomboWarning',
        ]);
        setFlags(item_flags);
        setUnavailableComoboWarning(item_flags.isIngredientcomboWarning);
        setItems(items_Detail);
        setQuickAddOns(quick_AddOns);
        setItemsData(itemData);
        setUtensilsData(utensils_Data);
        setShowYellowSticker(isYellowSticker);
        setDictionaryLables(dictionary_Lables);
        setErrorDictionary(error_Dictionary);
        setCartId(cart_Id);

        const updatedVehicleColor = vehicleColor ?? orderType_Details.curbDetails?.vehicleColor;
        const updatedVehicleType = vehicleType ?? orderType_Details.curbDetails?.vehicleType;
        setOrderTypeDetails({
            ...orderType_Details,
            curbDetails: {
                ...orderType_Details.curbDetails,
                vehicleColor: updatedVehicleColor,
                vehicleType: updatedVehicleType,
            }
        });
        setSelectedVehicle(updatedVehicleType);
        setSelectedColor(updatedVehicleColor);
        
        setOrderDetailsData(orderDetailsData);
        orderViewModel.orderType = orderType_Details.fulfillmentType;
        orderViewModel.isNavigateCombo = false;
        orderViewModel.isApplyCombo = false;
        orderViewModel.save();
        window.defaultStoreHeader();
        setError(error_Details);
        setIsRemoveItem(false);
        setGeneralProps(general_Props);
        setItemsSummary(items_Summary);
        if (!isRefetch) {
          const productList = getProductList(items_Detail);
          const promoCodeValue = _get(data, 'summaryData.promoCode', null);
          const offersList = data?.summaryData?.offers;
          setProductStringData(productList);
          setAnalyticsProducts({
            products: productList,
            productPriceView: 1,
            productQuantityView: 1,
            MIAMPriceView: 1,
            ExtrasPriceView: 1,
            bagViewed: 1,
            ComboPriceView: 1,
            promoView: promoCodeValue && 1,
            promoCode: promoCodeValue && promoCodeValue,
            promo: offersList.map((val: any) => val.offerDescription.toLowerCase()),
            warningMessage: offersList
              .filter((val: any) => val.applied === false)
              .map((vals: any) => data.summaryData.orderSummaryData.promoQualifiedItems),
            warningMessageUI: offersList.every((element: any) => element.applied === true) ? 0 : 1,
            offerDesc: offersList.map((val: any) => val.offerDescription.toLowerCase()),
            offerID: offersList.map((val: any) => val.offerId.toLowerCase()),
          });
          setTimeout(() => {
            if (orderViewModel.currentOrder && orderViewModel.currentOrder.cartQuantity > 0) {
              setAttemptTracker([{ name: CART_PAGEVIEW }]);
            }
          }, 100);
        }

        if (
          item_flags.isIngredientWarning ||
          item_flags.hasAllOutStockProducts ||
          item_flags.hasSomeOutStockProducts ||
          item_flags.hasAllOutStockcombo
        ) {
          setTimeout(() => {
            setIsModal(true);
            setCurbsidePopup(false);
            const errorWarning = error_Dictionary.unavailableProductsSubcopyA;
            const unavailableItems = prepareErrorMessage(flags, items_Detail, error_Dictionary);
            setAnalyticsProducts({
              virtualName: OUT_OF_STOCK_MODAL,
              warningMessageUI: 1,
              warningMessage: unavailableItems && errorWarning + unavailableItems,
            });
            setAttemptTracker([{ name: MODAL_PAGEVIEW }]);
          }, 600);
        } else if (isRefetchOnContinue) {
          if (checkoutContinue) {
            const productList = getProductList(items_Detail);
            const isCurbsideEvent = curbsidePopup ? 0 : 1;
            setAnalyticsProducts({
              clickActionName: checkoutContinue,
              componentTitle: curbsidePopup ? VEHICLE_MODAL : '',
              products: productList,
              productPriceCheckout: isCurbsideEvent,
              productQuantityCheckout: isCurbsideEvent,
              MIAMPriceCheckout: isCurbsideEvent,
              ExtrasPriceCheckout: isCurbsideEvent,
              ComboPriceCheckout: isCurbsideEvent,
              checkoutstarted: isCurbsideEvent,
              virtualName: curbsidePopup ? CURBSIDE_POPUP : '',
              cartContinue: isLoggedIn && 1,
            });
            let btnName = GUESTCHECKOUT;
            if (isLoggedIn || deliverySelection) {
              btnName = CARTCONTINUE;
            }
            if (checkoutContinue == CONTINUETOPAYMENT) {
              btnName = CONTINUETOPAYMENT;
            }
            setAttemptTracker([
              {
                name: btnName,
              },
            ]);
          }
          setIsrefetchOM(true);
          setCurbsidePopup(false);
        }
        if (orderViewModel.store.stateProvCode == CULTURE_CODE_CA) {
          setShowProp(true);
        }
        if (isQuickAddOnAdded) {
          setIsQuickAddOnAdded(false);
          const lastStack = [items_Detail[items_Detail.length - 1]];
          setAnalyticsProducts({
            products: getProductList([
              { ...lastStack[0], addToBagSource: CART_QUICKADDONS_SOURCE },
            ]),
            clickActionName: quickAddonsAnalytics.item.displayName,
            componentTitle: QUICK_ADD_ONS,
            itemaddedtobag: 1,
            productPriceAdded: 1,
            productQuantityAdded: 1,
            toasted: 0,
            ComboPriceAdded: 1,
            MIAMPriceAdded: 1,
            ExtrasPriceAdded: 1,
            quickAddonSelected: 1,
          });
          orderViewModel.currentOrder.cartQuantity = data.orderQuantity;
          orderViewModel.OrderSourceType = QUICK_ADD_ONS;
          orderViewModel.comboSource = null;
          orderViewModel.save();
          window.setCookie();
          setCartQuantity(data.orderQuantity);
          setAttemptTracker([{ name: ADD_TO_BAG }]);
          setTimeout(() => {
            (window as any).getBannerMessage('success', itemsData.addItemMessage);
            toastBannerAnalytics(setAnalyticsProducts, setAttemptTracker, itemsData.addItemMessage);
          }, 100);
        }
      }

      if (data.orderSummaryData || data.summaryData) {
        const extra_Banner_Text = _get(data, 'summaryData.orderSummaryData.extraBannerText', null);
        setSummaryData(data.summaryData);
        if (data.orderSummaryData) {
          setRemoveItemData(data.orderSummaryData);
          setUtensilsData(_get(data, 'orderSummaryData.utensilsData', []));
        }
        if (data.summaryData.orderSummaryData) {
          setSummaryLabels(data.summaryData.orderSummaryData);
        }
        setExtraBannerText(extra_Banner_Text);
        const index = orderViewModel?.redeemedOffers?.indexOf(offerIdData);
        if (index > -1) {
          orderViewModel.redeemedOffers.splice(index, 1);
          orderViewModel.save();
        }
        setOfferIdData('');
        if (data.summaryData.offers.length == 0) {
          orderViewModel.promosAndDeals.isPromoCodeApplied = false;
        }
      }

      if (!_isEmpty(data.emptyCartInfo)) {
        setShowYellowSticker(false);
        setTimeout(() => {
          setAnalyticsProducts({
            productPriceView: 1,
            productQuantityView: 1,
            MIAMPriceView: 1,
            ExtrasPriceView: 1,
            bagViewed: 1,
            ComboPriceView: 1,
            pageName: EMPTYBAG,
          });
          setAttemptTracker([{ name: EMPTY_CART_PAGEVIEW }]);
        }, 100);

        setFlags({});
        setUnavailableComoboWarning([]);
        setItems([]);
        setItemsData([]);
        setDictionaryLables({});
        setErrorDictionary({});
        setQuickAddOns(null);
        setOrderTypeDetails({});
        setOrderDetailsData({});
        setError({});
        setIsRemoveItem(false);
        setGeneralProps({});
        setInvalidPromoData({});
        setNotOfferedData({});
        setIsModal(false);
        setClearCart(false);
        orderViewModel.flushCartExceptCartId();
        setEmptyCartInfo(data.emptyCartInfo);
        orderViewModel.currentOrder.cartQuantity = 0;
        const index = orderViewModel.redeemedOffers.indexOf(comboMeal.offerId);
        if (index > -1) {
          orderViewModel.redeemedOffers.splice(index, 1);
        }
        orderViewModel.currentOrder.offers.IsPromoOfferID = false;
        orderViewModel.promoCode = {};
        orderViewModel.promosAndDeals.isPromoCodeApplied = false;
        orderViewModel.save();
        window.setCookie();
        setCartQuantity(0);
      }

      if (orderViewModel.orderType === DELIVERY) {
        setDeliverySelection(true);
      }
      //when promo code is not valid
      if (data.invalid) {
        setAnalyticsProducts({
          clickActionName: PROMO_CODE_APPLY,
          promoApplied: 0,
          promoError: 1,
          errorMessageEvent: 1,
          errorMessage: data.message,
          promoCode: vanityCode,
        });
        setInvalidPromoData(data);
        setNotOfferedData({});
        setAttemptTracker([{ name: CART_PROMO_CODE_APPY }]);
      }

      //when promo code is not offered by store
      if (data.notOffered) {
        setAnalyticsProducts({
          clickActionName: PROMO_CODE_APPLY,
          promoApplied: 0,
          promoError: 1,
          errorMessageEvent: 1,
          errorMessage: data.message,
          promoCode: vanityCode,
        });
        setNotOfferedData(data);
        setInvalidPromoData({});
        setAttemptTracker([{ name: CART_PROMO_CODE_APPY }]);
      }

      //promo code is valid and applied
      if (
        isVanityCode &&
        data.summaryData &&
        data.summaryData.offers &&
        data.summaryData.offers.length > 0 &&
        data.summaryData.offers[0].applied
      ) {
        orderViewModel.currentOrder.offers.Applied = data.summaryData.offers[0].applied;
        orderViewModel.currentOrder.offers.DiscountAmount =
          data.summaryData.offers[0].discountAmount;
        orderViewModel.currentOrder.offers.IsPromoOfferID =
          data.summaryData.offers[0].isPromoOfferID;
        orderViewModel.currentOrder.offers.OfferDescription =
          data.summaryData.offers[0].offerDescription;
        orderViewModel.currentOrder.offers.OfferId = data.summaryData.offers[0].offerId;
        orderViewModel.promoCode.promo_OfferID = data.summaryData.offers[0].offerId;
        orderViewModel.promoCode.promo_OfferUri = data.summaryData.offers[0].offerUri;
        orderViewModel.promoCode.promo_DiscountAmount = data.summaryData.offers[0].discountAmount;
        orderViewModel.promoCode.promo_OfferDescription =
          data.summaryData.offers[0].offerDescription;
        orderViewModel.save();
        setAnalyticsProducts({
          offerID: data.summaryData.offers[0].offerId,
          offerDesc: data.summaryData.offers[0].offerDescription,
        });
        setTimeout(() => {
          setAnalyticsProducts({
            clickActionName: PROMO_CODE_APPLY,
            promoApplied: 1,
            promoError: 0,
            promoCode: vanityCode,
          });
          setAttemptTracker([{ name: CART_PROMO_CODE_APPY }]);
        }, 100);
        setIsVanityCode(false);
        setNotOfferedData({});
      }

      if (data.summaryData && _isEmpty(data.summaryData.offers)) {
        orderViewModel.currentOrder.offers.IsPromoOfferID = false;
        orderViewModel.promoCode = {};
        orderViewModel.currentOrder.offers = {};
        orderViewModel.save();
      }
      if (
        isVanityCode &&
        data.summaryData &&
        data.summaryData.offers &&
        data.summaryData.offers.length > 0 &&
        !data.summaryData.offers[0].applied
      ) {
        setNotOfferedData({});
        setInvalidPromoData({});
      }
      if (data.comboProducts && isRefetch) {
        const comboData: any = prepareComboData(data.comboProducts);
        setCombos(comboData);
        setIsRefetch(false);
        if (orderViewModel.scLanguageCode == 'PR' && comboData.length > 1) {
          setIsUpSellModal(true);
          setAnalyticsProducts({
            virtualName: UPSELL_MODAL,
          });
          setAttemptTracker([{ name: MODAL_PAGEVIEW }]);
          reactEngageApptentiveEvent('cart make it a meal', true, '');
        } else {
          orderViewModel.selectedCombo = {
            comboId: data.comboProducts[0].masterPromotionId,
            productId: data.comboProducts[0].productId,
          };
          orderViewModel.comboSource = 'cart';
          orderViewModel.save();
          reactEngageApptentiveEvent('cart make it a meal', true, pdpLink);
        }
      }

      if (data.removeCombosSidesAsyncResponse) {
        const summary_Data = _get(data, ['removeCombosSidesAsyncResponse', '0', 'summaryData']);
        setSummaryData(summary_Data);

        const matchingItems = data.removeCombosSidesAsyncResponse.filter((item: any) => {
          return items.some(
            (removedItem: Items) => removedItem.cartItemId === item.orderSummaryData.cartItemId,
          );
        });

        const removeItems = matchingItems.map((item: any) => item.orderSummaryData);

        const updatedCartItems = items.map((item: Items) => {
          if (removeItems.some((removedItem: any) => removedItem.cartItemId === item.cartItemId)) {
            const index = removeItems.findIndex(
              (removedItem: any) => removedItem.cartItemId === item.cartItemId,
            );
            const mealBasePrice = _get(removeItems, [index, 'mealBasePrice']);
            return {
              ...item,
              isMealEligible: true,
              isMeal: false,
              mealBasePrice: mealBasePrice,
            };
          } else {
            return item;
          }
        });
        setItems(updatedCartItems);
        setIsModal(false);
      }

      if (data.error) {
        setErrors(data.error);
        setErrorDictionary(data.error.dictionaryLables);
      }

      if (data.setSpecialInstructions) {
        getRequest({
          apiEndpoint: isLoggedIn
            ? generalProps.checkoutTokenAPI
            : generalProps.checkoutTokenGuestAPI,
          cartId: orderViewModel.currentOrder.cartId,
          siteName: siteName,
          storeId: orderViewModel.store.id,
          payPalBanner: false,
        });
      }

      if (data.token) {
        setContextLoader({
          buttonId: ternaryCheck(isLoggedIn, ORDER_CHECKOUT_LOGIN, LOGGEDOUT_CONTINUE),
          isLoading: true,
          animationColor: ternaryCheck(isLoggedIn, WHITE, GREEN)
        });
        const loggedOut = _get(orderTypeDetails, ['loggedOutData', 'payUrl'], '');
        const loggedIn = _get(orderTypeDetails, ['loggedData', 'payUrl'], '');
        const url = !_isEmpty(loggedOut) ? loggedOut : loggedIn;
        setIsrefetchOM(false);
        reactEngageApptentiveEvent('cart continue order', true, url, data.token);
      }

      if (removalType === CLEAR_CART || removalType === ORDER_SOMETHING_ELSE) {
        const productList = getProductList(items);
        if (removalType === ORDER_SOMETHING_ELSE) {
          setAnalyticsProducts({
            clickActionName: ORDERSOMETHINGELSE,
            componentTitle: OUT_OF_STOCK_MODAL,
            products: productList,
            itemRemoved: 1,
            productPriceRemove: 1,
            productQuantityRemove: 1,
            MIAMPriceRemove: 1,
            ExtrasPriceRemove: 1,
            ComboPriceRemove: 1,
            virtualName: OUT_OF_STOCK_MODAL,
          });
          orderViewModel.currentOrder.cartQuantity = 0;
          orderViewModel.save();
          window.setCookie();
          setCartQuantity(0);
          setTimeout(() => {
            window.location.href = subwayScript.navigation.getMenuUrl(orderViewModel.store.id);
          }, 100);
        } else {
          setAnalyticsProducts({
            clickActionName: CLEARMYBAG,
            products: productList,
            itemRemoved: 1,
            productPriceRemove: 1,
            productQuantityRemove: 1,
            MIAMPriceRemove: 1,
            ExtrasPriceRemove: 1,
            ComboPriceRemove: 1,
          });
          emptyRedeemedRewards();
        }
        setAttemptTracker([{ name: REMOVE_FROM_CART }]);
      }
      if (data.storeCloseThreshold) {
        (window as any).getBannerMessage(
          'error',
          data.storeCloseDictionary,
          false,
          false,
          null,
          null,
          null,
          '.header-sticky',
          'gray',
        );
        const height = bannerHeight();
        setBannerHeightCal(height);
      }
      localStorage.removeItem('cartProgressBar');

      const { fulFillmentType, vehicleColor, vehicleType } = data;

      if (fulFillmentType === CURBSIDE || fulFillmentType === PICKUP) {
        orderViewModel.orderType = fulFillmentType;
        orderViewModel.save();

        setOrderTypeDetails((prevDetails: { curbDetails: any }) => ({
          ...prevDetails,
          fulfillmentType: fulFillmentType || null,
          curbDetails: ternaryCheck(
            fulFillmentType === CURBSIDE,
            {
              ...prevDetails.curbDetails,
              vehicleColor,
              vehicleType,
            },
            prevDetails.curbDetails,
          ),
        }));

        setSummaryData(_get(data, 'summaryData', {}));
        setOrderDetailsData(_get(data, 'orderDetailsData', {}));
      }
      
      if (fulFillmentType === CURBSIDE) {
        setCurbsidePopup(false);
      }
      if(data?.pickupDateTime){
        setPickupDateTime(data?.pickupDateTime)
      }

    }

    if(apiErrors) {
      setClearCart(false);
    }
  }, [data, isRefetch, apiErrors]);

  
  useEffect(() => {
    const updatedUtensilStorageData: any[] = [];
    utensilsData?.forEach((utensil: any) => {
      const existingItemIndex = utensilStorage.findIndex((item: any) => item.id === utensil.id);
      if (existingItemIndex !== -1) {
        updatedUtensilStorageData.push(utensilStorage[existingItemIndex]);
      } else {
        updatedUtensilStorageData.push(utensil);
      }
    });
    if (!_isEmpty(utensilsData)) {
      sessionStorage.setItem('utensilObject', JSON.stringify(updatedUtensilStorageData));
    }
    setUtensilsDataUpdated(updatedUtensilStorageData);
    getSpecialInstruction(instruction, updatedUtensilStorageData);
  }, [utensilsData]);

  useEffect(() => {
    if (orderViewModel.showRedirectSuccessBanner && data) {
      setTimeout(() => {
        (window as any).getBannerMessage('success', orderViewModel.redirectBannerMessage);
        toastBannerAnalytics(
          setAnalyticsProducts,
          setAttemptTracker,
          orderViewModel.redirectBannerMessage,
        );
        orderViewModel.showRedirectSuccessBanner = false;
        orderViewModel.redirectBannerMessage = '';
        orderViewModel.save();
      }, 300);
    }
  }, [orderViewModel.showRedirectSuccessBanner, data]);

  useEffect(() => {
    if (items && !_isEmpty(removeItemData)) {
      if (
        (multipleItemsToRemove.length > 0 || handleRemove) &&
        (_isEmpty(comboMeal) || (!_isEmpty(comboMeal) && !comboMeal.isMeal))
      ) {
        let filteredData;

        if (multipleItemsToRemove.length > 0) {
          filteredData = items.filter(list => !multipleItemsToRemove.includes(list.cartItemId));
        } else {
          filteredData = items.filter((list: any) => list.cartItemId !== removeItemData.cartItemId);
        }
        setItems(filteredData as any);
        orderViewModel.currentOrder.cartQuantity = removeItemData.cartQuantity;
        const index = orderViewModel.redeemedOffers.indexOf(comboMeal.offerId);
        if (index > -1) {
          orderViewModel.redeemedOffers.splice(index, 1);
        }
        orderViewModel.defaultGray = false;
        orderViewModel.save();
        window.setCookie();
        setCartQuantity(removeItemData.cartQuantity);
        setIsRemoveItem(false);
        setHandleRemove(false);
        setMultipleItemsToRemove([]);
        setIsModal(false);
        setTimeout(() => {
          (window as any).getBannerMessage('info-gray', itemsData.itemsRemovedText);
          toastBannerAnalytics(setAnalyticsProducts, setAttemptTracker, itemsData.itemsRemovedText);
        }, 100);
      }
      if (copyCart) {
        const copyItems = cloneDeep(items);
        const analyticsData = copyItems.map((list: any) => {
          if (list.cartItemId == removeItemData.cartItemId) {
            list.quantity = decreaseQuantity
              ? list.quantity - removeItemData.cartItemQuantity
              : removeItemData.cartItemQuantity - list.quantity;
            list.price = decreaseQuantity
              ? list.price - removeItemData.cartItemPrice
              : removeItemData.cartItemPrice - list.price;
          }
          return list;
        });
        const productList = getProductList(analyticsData, removeItemData.cartItemId);
        if (decreaseQuantity) {
          setAnalyticsProducts({
            products: productList,
            clickActionName: DECREASE_QUANTITY,
            productPriceRemove: 1,
            productQuantityRemove: 1,
            MIAMPriceRemove: 1,
            ExtrasPriceRemove: 1,
            itemRemoved: 1,
            ComboPriceRemove: 1,
          });
        } else {
          setAnalyticsProducts({
            products: [{ ...(productList[0] as object), addToBagSource: CART_INCREMENT_SOURCE }],
            clickActionName: INCREASE_QUANTITY,
            itemaddedtobag: 1,
            ComboPriceAdded: 1,
            productPriceAdded: 1,
            productQuantityAdded: 1,
            MIAMPriceAdded: 1,
            ExtrasPriceAdded: 1,
          });
        }
        const filteredData = copyItems.map((list: any) => {
          if (list.cartItemId == removeItemData.cartItemId) {
            list.quantity = removeItemData.cartItemQuantity;
            list.price = removeItemData.cartItemPrice;
          }
          return list;
        });
        setItems(filteredData);
        orderViewModel.currentOrder.cartQuantity = removeItemData.cartQuantity;
        orderViewModel.save();
        setCartQuantity(removeItemData.cartQuantity);
        window.setCookie();
        setCopyCart(false);
        setAttemptTracker([{ name: decreaseQuantity ? REMOVE_FROM_CART : ADD_TO_BAG }]);
        setDecreaseQuantity(false);
      }
      if (!_isEmpty(comboMeal) && comboMeal.isMeal && handleRemove) {
        const updatedCartItems = items.map((item: any) => {
          if (item.cartItemId === removeItemData.cartItemId) {
            return {
              ...item,
              isMealEligible: true,
              isMeal: false,
              meal: null,
              price: removeItemData.cartItemPrice,
              mealBasePrice: removeItemData.mealBasePrice,
            };
          } else {
            return item;
          }
        });
        const productListData = getProductList(updatedCartItems, removeItemData.cartItemId);
        setAnalyticsProducts({
          products: productListData,
          clickActionName: orderViewModel.scLanguageCode == 'PR' ? REMOVE_COMBO : REMOVE_MIAM,
          productPriceUpdate: 1,
          productQuantityUpdate: 1,
          MIAMPriceUpdate: 1,
          ExtrasPriceUpdate: 1,
          updateCart: 1,
          MIAMSelected: 0,
          ComboPriceUpdate: 1,
        });

        setItems(updatedCartItems);
        setIsRemoveItem(false);
        setComboMeal({});
        setHandleRemove(false);
        setAttemptTracker([{ name: UPDATE_CART }]);
      }
    }
  }, [items && removeItemData, comboMeal]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [instruction]);

  const setItemToRemoveHandler = (
    cartItemId: string,
    isMeal: boolean,
    quantity: number,
    isRemove: boolean,
    offerId: string,
    isBundleItem?: boolean,
    bundleId?: string,
  ) => {
    setItemToRemove(cartItemId);
    setComboMeal({ isMeal, quantity, isRemove, offerId, isBundleItem, bundleId: bundleId || '' });
    setIsRemoveItem(true);
  };

  const onClickQuickAddonHandler = (item: any, index: number, length: number) => {
    setQuickAddonsAnalytics({
      item: item,
    });
    const itemDetails = {
      productName: item.displayName,
      productId: item.id,
      productOriginSource: QUICK_ADD_ONS,
      categoryName: item.categoryName,
      categoryId: item.categoryId,
    };
    setSelectedCard(index + 1);
    setTotalCards(length);
    setIsRefetch(true);
    setIsQuickAddOnAdded(true);
    postRequest({
      apiEndpoint: generalProps.addItemAsyncAPI,
      StoreId: orderViewModel.store.id,
      LocationId: orderViewModel.store.id,
      CartId: orderViewModel.currentOrder.cartId,
      pricingScheme: '1',
      fulfillmentType: orderViewModel.orderType || 'pickup',
      dineIn: false,
      IsCartPage: true,
      DatasourceId: dataSource,
      IsQuickaddon: true,
      IsReactResponse: true,
      curbside: {
        curbsideInstructions: ' ',
      },
      orderInstructions: ' ',
      sitename: siteName,
      items: addToCartItems(1, itemDetails, item.masterProductId, [], null),
    });
  };
  const handleOnChange = (position: any, utensilsList: any) => {
    const updatedUtensilsData = [...utensilsList];
    updatedUtensilsData[position].defaultValue = !updatedUtensilsData[position].defaultValue;
    setUtensilsDataUpdated(updatedUtensilsData);
    sessionStorage.setItem('utensilObject', JSON.stringify(utensilsDataUpdated));
    getSpecialInstruction(instruction, utensilsList);
  };
  const itemRemove = (
    cartItemId: string,
    quantity: number,
    isRewardRemove: boolean,
    isBundleItem: boolean,
    bundleId: string,
  ) => {
    setDecreaseQuantity(true);
    setCopyCart(true);
    setIsRefetch(true);
    postRequest({
      apiEndpoint: generalProps.removeItemAsyncAPI,
      CartItemId: cartItemId,
      CartId: cartId,
      CartItemQty: quantity,
      DatasourceId: dataSource,
      StoreId: orderViewModel.store.id,
      CurrentTierLevel: orderViewModel.Analytics.tierLevel,
      hasStrawSelection: _get(orderTypeDetails, ['hasStrawSelection']),
      hasUtensilSelection: _get(orderTypeDetails, ['hasUtensilSelection']),
      isBundleItem: isBundleItem,
      bundleId: bundleId || '',
    });
  };
  const handleRemoveSides = (actionName: string) => {
    setIsRefetch(true);
    const removeData = items.find(remove_items => {
      return remove_items.cartItemId == itemToRemove;
    });
    if (itemToRemove && !comboMeal.isMeal) {
      const productList = getProductList(items, itemToRemove);
      setAnalyticsProducts({
        products: productList,
        clickActionName: REMOVE_FROM_BAG,
        productPriceRemove: 1,
        productQuantityRemove: 1,
        MIAMPriceRemove: 1,
        ExtrasPriceRemove: 1,
        itemRemoved: 1,
        ComboPriceRemove: 1,
      });
      setAttemptTracker([{ name: REMOVE_FROM_CART }]);
      if (removeData?.isRewards) {
        setAnalyticsProducts({
          clickActionName: REWARD_REMOVED,
          rewardItemRemoved: 1,
          rewardName: removeData.rewardData?.offerDescription,
        });
        setAttemptTracker([{ name: 'reward_remove', isClickable: true }]);
      }
    }
    setHandleRemove(true);
    if (comboMeal.isMeal) {
      postRequest({
        apiEndpoint: generalProps.removeSidesAsyncAPI,
        CartItemId: itemToRemove,
        CartId: cartId,
        DatasourceId: dataSource,
        CurrentTierLevel: orderViewModel.Analytics.tierLevel,
        hasStrawSelection: _get(orderTypeDetails, ['hasStrawSelection']),
        hasUtensilSelection: _get(orderTypeDetails, ['hasUtensilSelection']),
      });
    } else {
      setHandleRemove(true);
      postRequest({
        apiEndpoint: generalProps.removeItemAsyncAPI,
        CartItemId: itemToRemove,
        CartId: cartId,
        CartItemQty: comboMeal.quantity,
        DatasourceId: dataSource,
        StoreId: orderViewModel.store.id,
        IsRemoveItem: ternaryCheck(comboMeal.isRemove, true, false),
        OfferId: comboMeal.offerId,
        IsReward: !_isEmpty(comboMeal.offerId),
        hasStrawSelection: _get(orderTypeDetails, ['hasStrawSelection']),
        hasUtensilSelection: _get(orderTypeDetails, ['hasUtensilSelection']),
        CurrentTierLevel: orderViewModel.Analytics.tierLevel,
        isBundleItem: comboMeal.isBundleItem,
        bundleId: comboMeal.bundleId || '',
      });
    }
  };

  const closeModal = () => {
    setIsModal(false);
    setClearCart(false);
    setIsRemoveItem(false);
    setAnalyticsProducts({
      virtualName: '',
    });
  };

  const removeItemsFromCart = (removal_Type: string) => {
    setIsRefetch(true);
    setRemovalType(removal_Type);
    let cartItemList;
    let bundleItemList;
    if (removal_Type === CLEAR_CART || removal_Type === ORDER_SOMETHING_ELSE) {
      cartItemList = items.map(item => !item.isBundleItem ? item.cartItemId : null).filter(cartItemId => cartItemId !== null);
      bundleItemList = items.map(item => item.isBundleItem ? item.cartItemId : null).filter(cartItemId => cartItemId !== null);
    }
    if (removal_Type === REMOVE_ITEMS_FROM_CART) {
      cartItemList = items.filter(item => !item.isBundleItem && !item.inStock).map(item => item.cartItemId);
      bundleItemList = items.filter(item => item.isBundleItem && !item.inStock).map(item => item.cartItemId);
      setMultipleItemsToRemove([...cartItemList, ...bundleItemList]);
      const productList = getProductList(items, cartItemList[0]);
      setAnalyticsProducts({
        clickActionName: REMOVEITEMFROMCART,
        componentTitle: OUT_OF_STOCK_MODAL,
        products: productList,
        itemRemoved: 1,
        productPriceRemove: 1,
        productQuantityRemove: 1,
        MIAMPriceRemove: 1,
        ExtrasPriceRemove: 1,
        ComboPriceRemove: 1,
        virtualName: OUT_OF_STOCK_MODAL,
      });
      setAttemptTracker([{ name: REMOVE_FROM_CART }]);
    }
    if (removal_Type === REMOVE_COMBO_ITEMS) {
      cartItemList = cultureLanguage.includes(orderViewModel.scLanguageCode) ? items
        .filter(item => !item.isBundleItem && item.outOfStockMIAM != null)
        .map(item => item.cartItemId) :
        items
          .filter(item => item.outOfStockMIAM != null)
          .map(item => item.cartItemId).join(',');
      bundleItemList = items.filter(item => item.isBundleItem && item.outOfStockMIAM != null).map(item => item.cartItemId)
      if (items.length > 1) {
        setMultipleItemsToRemove([...cartItemList, ...bundleItemList]);
      }
      const payload = {
        apiEndpoint: cultureLanguage.includes(orderViewModel.scLanguageCode) ? generalProps.removeMultipleItemAsyncAPI : generalProps.removeCombosSides,
        ...(cultureLanguage.includes(orderViewModel.scLanguageCode) ? { CartItemIdList: cartItemList } : { CartItemId: cartItemList }),
        BundleItemIdList: bundleItemList,
        CartId: cartId,
        siteName: siteName,
        DatasourceId: dataSource,
        CurrentTierLevel: orderViewModel.Analytics.tierLevel
      };
      postRequest(payload);
      return;
    }
    postRequest({
      apiEndpoint: generalProps.removeMultipleItemAsyncAPI,
      CartItemIdList: cartItemList,
      BundleItemIdList: bundleItemList,
      CartId: cartId,
      ClearCart: removal_Type === CLEAR_CART,
      StoreId: orderViewModel.store.id,
      DatasourceId: dataSource,
      CurrentTierLevel: orderViewModel.Analytics.tierLevel,
    })
      sessionStorage.removeItem('utensilObject');
  };

  const copyCartItem = (
    cartItemId: string,
    cart_Id: string,
    categoryId: string,
    masterProductId: number,
    productId: number,
    isBundleItem: boolean,
    bundleId: string,
  ) => {
    setCopyCart(true);
    const products = {
      CartItemId: cartItemId,
      ProductId: productId.toString(),
      CategoryId: categoryId,
      MasterProductId: masterProductId.toString(),
    };
    const result = [...cartProducts, products];
    setCartProducts(result);
    setIsRefetch(true);
    postRequest({
      apiEndpoint: generalProps.copyCartItemAPI,
      StoreId: orderViewModel.store.id,
      CartItemId: cartItemId,
      CartId: cart_Id,
      CartProducts: result,
      DatasourceId: dataSource,
      CurrentTierLevel: orderViewModel.Analytics.tierLevel,
      hasStrawSelection: _get(orderTypeDetails, ['hasStrawSelection']),
      hasUtensilSelection: _get(orderTypeDetails, ['hasUtensilSelection']),
      isBundleItem: isBundleItem,
      bundleId: bundleId,
    });
  };

  const applyPromoCode = (promoCode: string) => {
    if (promoCode) {
      setIsVanityCode(true);
      setVanityCode(promoCode);
      setIsRefetch(true);
      postRequest({
        apiEndpoint: generalProps.applyPromoCodeAPI,
        country: orderViewModel.headerFooterLanguageCode.includes('-CA') ? 'can' : 'usa',
        storeId: orderViewModel.store.id,
        cartID: cartId,
        DatasourceId: dataSource,
        CurrentTierLevel: orderViewModel.Analytics.tierLevel,
        Offers: [
          {
            promoCode: promoCode,
          },
        ],
      });
    }
  };

  const handleApplyOrEdit = (
    link: string,
    productId: number,
    actionType: string,
    cartItemId: string,
    offerId: string,
    isKidsMeal: boolean,
    isBundleItem: boolean,
    bundleId: string,
  ) => {
    if (actionType == EDIT) {
      setIsRefetch(true);
      setAnalyticsProducts({
        clickActionName: `${getComboClickActionName(isKidsMeal)} ${actionType}`,
      });
      setAttemptTracker([{ name: CART_CTA }]);

      window.orderViewModel.comboEditClick = true;
      window.orderViewModel.save();
      setTimeout(() => {
        reactEngageApptentiveEvent('cart make it a meal', true, link);
      }, 100);
    }
    if (actionType == APPLY) {
      setIsRefetch(true);
      setAnalyticsProducts({
        clickActionName: _isEqual(orderViewModel.scLanguageCode, PR)
          ? `combo ${actionType}`
          : `miac ${actionType}`,
      });
      setAttemptTracker([{ name: CART_CTA }]);
      if (cultureLanguage.includes(orderViewModel.scLanguageCode)) {
        orderViewModel.isApplyCombo = true;
      }
      window.orderViewModel.comboEditClick = false;
      window.orderViewModel.save();
      getRequest({
        apiEndpoint: generalProps.getComboAPI,
        productId: productId,
        restaurantId: orderViewModel.store.id,
        fullfilmentType: orderViewModel.orderType,
        siteName: siteName,
      });
      setPDPLink(link);
    }
    if (actionType == REMOVE) {
      setItemToRemoveHandler(cartItemId, false, 1, true, offerId, isBundleItem, bundleId || '');
    }
  };
  const addCombofromUpsell = (id: any, isAddtoCart: boolean) => {
    const selectedCombo = combos.find((list: any) => list.masterPromotionId == id);
    const findIndexById = combos.findIndex((list: any) => list.masterPromotionId === id);
    setAnalyticsProducts({
      clickActionName: MAKE_IT_A_COMBO,
      virtualName: UPSELL_MODAL,
    });
    setSelectedCard(findIndexById + 1);
    setTotalCards(combos.length);
    orderViewModel.selectedCombo = {
      comboId: selectedCombo.masterPromotionId,
      productId: selectedCombo.productId,
    };
    orderViewModel.comboSource = CART;
    orderViewModel.save();
    setTimeout(() => {
      window.location.href = pdpLink;
    }, 100);
    setAttemptTracker([{ name: CART_CTA }]);
  };

  const handleOkClick = (outOfStock: string) => {
    setAnalyticsProducts({
      clickActionName: OK_CTA,
      componentTitle: outOfStock,
    });
    setAttemptTracker([{ name: 'cart_click_action' }]);
    setTimeout(() => {
      const firstItemWithMissingIngredients =
        items[items.findIndex(itemObj => itemObj.noIngredients.length !== 0)];
      window.orderViewModel.comboEditClick = false;
      window.orderViewModel.save();
      window.location.href = subwayScript.navigation.getCartItemCustomizerUrl(
        orderViewModel.store.id,
        cartId,
        firstItemWithMissingIngredients.cartItemId,
        firstItemWithMissingIngredients?.analytics?.categoryId,
        orderViewModel.headerFooterLanguageCode,
      );
    }, 0);
  };
  const onPromoClose = (list: any) => {
    setIsRefetch(true);
    postRequest({
      apiEndpoint: generalProps.removeDealsAPI,
      CartId: cartId,
      storeId: orderViewModel.store.id,
      DatasourceId: dataSource,
      CurrentTierLevel: orderViewModel.Analytics.tierLevel,
      Offers: [
        {
          OfferId: list.offerId,
          OfferUri: list.offerUri,
        },
      ],
    });
    setAnalyticsProducts({
      dealRemoved: 1,
      promo: [list.offerDescription.toLowerCase()],
      offerDesc: list.offerDescription.toLowerCase(),
      offerID: list.offerId,
      clickActionName: 'deal removed',
    });
    setAttemptTracker([{ name: REMOVE_OFFER_REWARD, isClickable: true }]);
    setOfferIdData(list.offerId);
  };
  const getSpecialInstruction = (textValue: string, utensilsListData: any) => {
    let instructionMessage = textValue.replace(/;/g, '');
    utensilsListData?.map((value: any) => {
      if (value.defaultValue) {
        instructionMessage = !_isEmpty(instructionMessage)
          ? `${instructionMessage};${value.apiText}`
          : _isEmpty(instructionMessage) ? `${value.apiText}` : `${';'}${value.apiText}`;
      }
    });
    setFinalInstructions(instructionMessage);
  };

  useEffect(() => {
    if (isrefetchOM) {
      setContextLoader({
        buttonId: ternaryCheck(isLoggedIn, ORDER_CHECKOUT_LOGIN, LOGGEDOUT_CONTINUE),
        isLoading: true,
        animationColor: ternaryCheck(isLoggedIn, WHITE, GREEN)
      });
      postRequest({
        apiEndpoint: generalProps.setSpecialInstructionsAPI,
        CartId: orderViewModel.currentOrder.cartId,
        sitename: siteName,
        Instructions: finalInstructions ?? instruction,
        IsCurbpickupEnabled: orderViewModel.orderType === ORDER_TYPE_CURBSIDE,
        CurbDetails: `Vehicle Type :${selectedVehicle}\r\nVehicle Color : ${selectedColor}\r\n`,
        OrderType: orderViewModel.orderType,
        PickupDate: null,
        DeliveryInstructions: orderViewModel.deliveryInstructions,
      });
    }
  }, [isrefetchOM]);

  const saveCurbsideData = (testAutoID: string) => {
    setIsRefetch(true);
    setContextLoader({
      buttonId: testAutoID,
      isLoading: true,
    });
    postRequest({
      apiEndpoint: _get(generalProps, 'updateOrderDetailsSectionApi'),
      IsCurbpickupEnabled: true,
      CurbDetails: `Vehicle Type :${selectedVehicle}\r\nVehicle Color : ${selectedColor}\r\n`,
      OrderType: CURBSIDE,
      sitename: siteName,
      StoreId: orderViewModel.store.id,
      CartId: orderViewModel.currentOrder.cartId,
      DatasourceId: dataSource,
      userLocalTime: getUtcTime(),
      CurrentTierLevel: orderViewModel.Analytics.tierLevel
    });
  };


  const saveInStorePickup = (testAutoID: string) => {
    setIsRefetch(true);
    setContextLoader({
      buttonId: testAutoID,
      isLoading: true,
    });
    postRequest({
      apiEndpoint: _get(generalProps, 'updateOrderDetailsSectionApi'),
      IsCurbpickupEnabled: false,
      OrderType: PICKUP,
      sitename: siteName,
      StoreId: orderViewModel.store.id,
      CartId: orderViewModel.currentOrder.cartId,
      DatasourceId: dataSource,
      CurrentTierLevel: orderViewModel.Analytics.tierLevel,
      userLocalTime: getUtcTime(),
    });
  }

  const refetchOrderManagement = (isCurbsidePopup = false) => {
    let continueCta = CONTINUEASGUEST;
    if (orderViewModel.hasLoggedIn || deliverySelection) {
      continueCta = CONTINUE;
    }
    if (curbsidePopup) {
      continueCta = CONTINUETOPAYMENT;
    }
    orderViewModel.orderStatusClicked = false;
    orderViewModel.save();
    const yesNo = orderViewModel.orderType === 'curbside' ? 'yes' : 'no';
    setAnalyticsProducts({
      curbsideAvailable: yesNo,
    });
    const continue_cta = continueCta?.toLowerCase();
    setCheckoutContinue(continue_cta);
    if (
      orderViewModel.orderType == CURBSIDE &&
      !isCurbsidePopup &&
      (continueCta == 'continue as guest' || 'continue')
    ) {
      setAnalyticsProducts({
        clickActionName: continueCta,
        products: productStringData,
        productPriceCheckout: 1,
        productQuantityCheckout: 1,
        MIAMPriceCheckout: 1,
        ExtrasPriceCheckout: 1,
        ComboPriceCheckout: 1,
        checkoutstarted: 1,
        curbsideSelected: 1,
      });
      setAttemptTracker([{ name: continue_cta == CONTINUE ? CARTCONTINUE : GUESTCHECKOUT }]);
      setTimeout(() => {
        setAnalyticsProducts({
          virtualName: 'curbside vehicle selection modal',
          vechicleSelected: 1,
        });
        setAttemptTracker([{ name: MODAL_PAGEVIEW }]);
      }, 100);
    }

    setIsRefetch(true);
    setIsRefetchOnContinue(true);
    postRequest({
      apiEndpoint: apiEndpoint.value,
      StoreId: orderViewModel.store.id,
      CartId: orderViewModel.currentOrder.cartId,
      DatasourceId: dataSource,
      sitename: siteName,
      IsContinue: true,
      IsQuickaddon: true,
      CurrentTierLevel: orderViewModel.Analytics.tierLevel,
    });
  };

  const updateModal = () => {
    const errorDisctionay: any = _get(apiErrors, 'response.data.error.errorCode', null);
    if (errorDisctionay != ERRORCODE_3007) {
      orderViewModel.currentOrder.cartQuantity = 0;
      orderViewModel.save();
      window.setCookie();
      setCartQuantity(0);
      window.location.href = subwayScript.navigation.getMenuUrlCartExpire(orderViewModel.store.id);
    }
  };

  const upSellCloseModal = (btnText: string) => {
    setAnalyticsProducts({
      clickActionName: btnText,
      virtualName: UPSELL_MODAL,
    });
    setAttemptTracker([{ name: CART_CTA }]);
    setIsUpSellModal(false);
    setTimeout(() => {
      setAnalyticsProducts({
        virtualName: '',
      });
    }, 100);
  };

  const keepShopping = () => {
    setAnalyticsProducts({
      startonlineorder: 0,
      clickActionName: KEEP_SHOPING,
    });
    setAttemptTracker([{ name: CART_CTA }]);
    setTimeout(() => {
      const href = `/${orderViewModel.headerFooterLanguageCode}/restaurant/${orderTypeDetails.storeData.storeId}/menu`;
      reactEngageApptentiveEvent('cart keep shopping', true, href);
    }, 100);
  };

  if (apiErrors) {
    const errorDisctionay: any = _get(apiErrors, 'response.data.error', null);
    if (errorDisctionay?.errorCode == 'somethingwentwrong') {
      return (
        <>
          {componentRenderCheck(showSticky, (
            <div className="order__header">
              <Heading
                className="order__title"
                level="h1"
                title={errorDisctionay.dictionaryLables.yourItems}
              />
              <Button
                testAutoId={'order-close-btn1'}
                className={`order__close`}
                onClick={() => window.history.go(-1)}
                icon={<SvgIconClose />}
              />
            </div>
          ))}
          <div className="order_EmptyCart">
            <EmptyCart error={errorDisctionay} SomethingWentWrong={true} />
          </div>
        </>
      );
    }
  }
  
  return (
    <div className="order__management__container">
      <div className="order-management">
        {componentRenderCheck(
          isUpSellModal,
          <UpSellOverlay
            title={itemsData.comboPopupTitle}
            combo={combos}
            Cals={'Cals'}
            modalIsOpen={isUpSellModal}
            onOutSideClick={false}
            closeModal={upSellCloseModal}
            selectCombo={addCombofromUpsell}
            customizerData={{ yesMakeItAMeal: itemsData.comboPopupCTA }}
            buttonText={itemsData.noThanksCTA}
          />,
        )}
        {componentRenderCheck(
          multipleAndCheck(isLoading, !isRefetch),
          <>
            <Skeleton
              count={6}
              className={`cart`}
              isImage={false}
              isButton={true}
              flexContent={'row'}
            />
            <Skeleton
              titleCount={2}
              count={1}
              className={`cartPricing`}
              isImage={false}
              isButton={true}
              flexContent={'row'}
            />
          </>,
        )}
        {componentRenderCheck(
          apiErrors,
          <ErrorModal error={apiErrors} value={CART} okText={okText} updateModal={updateModal} />,
        )}
        {!_isEmpty(emptyCartInfo) && (
          <>
            {componentRenderCheck(
              showSticky,
              <div className="order__header">
                <Heading className="order__title" level="h1" title={emptyCartInfo.yourItems} />
                <Button
                  testAutoId={'order-close-btn2'}
                  className={`order__close`}
                  onClick={() => window.history.go(-1)}
                  icon={<SvgIconClose />}
                />
              </div>,
            )}
            <div className="order_EmptyCart">
              <EmptyCart emptyCartInfo={emptyCartInfo} />
            </div>
          </>
        )}
        {componentRenderCheck(!_isEmpty(errors), <InfoModal error={errors} okText={okText} />)}
        {items && items.length > 0 && (
          <>
            <div className="order__list">
              <div className="order__header">
                {windowSize < 1024 && (
                  <Heading
                    className="order__title"
                    testAutoId="order-list-title"
                    level="h1"
                    title={itemsData.yourItems}
                  />
                )}
                <Button
                  testAutoId={'order-close-btn3'}
                  className={`order__close`}
                  aria-label="close"
                  onClick={() => window.history.go(-1)}
                  icon={<SvgIconClose />}
                />
              </div>
              {orderViewModel.orderType == DELIVERY && extraBannerText && (
                <div className="order__delivery">
                  <Image
                    className="order__delivery--info__image"
                    testAutoId="order-delivery-extra-image"
                    imageSources={deliveryIcon}
                  />
                  <Heading
                    level="p"
                    className="order__delivery--info__text"
                    title={extraBannerText}
                    testAutoId="order-delivery-extra-title"
                  />
                </div>
              )}
              <div>
                {showProp && (
                  <a
                    data-testauto-id={'prop65Warning'}
                    href={itemsData.prop65Url}
                    className="order__prop__warning"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Heading
                      level="span"
                      className="button__content"
                      title={itemsData.prop65Warning}
                      testAutoId="order-prop65-warning"
                    />
                    <SvgIconNext />
                  </a>
                )}
              </div>
              <div className="order__container">
                <OrderDetailsSection
                  orderDetailsData={orderDetailsData}
                  dictionaryLables={dictionaryLables}
                  orderTypeDetails={orderTypeDetails}
                  dataSource={dataSource}
                  generalProps={generalProps}
                  siteName={siteName}
                  cartId={cartId}
                  pickupDateTime={pickupDateTime}
                  setCurbsidePopup={setCurbsidePopup}
                  saveInStorePickup={saveInStorePickup}
                />
                {
                  <CartItems
                    orderTypeDetails={orderTypeDetails}
                    cartItems={items}
                    itemsData={itemsData}
                    orderViewModel={orderViewModel}
                    cartId={cartId}
                    dictionaryLables={dictionaryLables}
                    setItemToRemoveHandler={setItemToRemoveHandler}
                    copyCartItem={copyCartItem}
                    handleApplyOrEdit={handleApplyOrEdit}
                    itemRemove={itemRemove}
                    adaDictionaryData={itemsSummary.adaDictionaryData}
                  />
                }
                <div className="order__clear">
                  <div className="cart__header__button">
                    <Button
                      testAutoId={KEEP_SHOPPING_CTA}
                      onClick={() => {
                        setContextLoader({
                          buttonId: KEEP_SHOPPING_CTA,
                          isLoading: true,
                          ariaLabelText: dictionaryLables?.ariaLabelText
                        });
                        keepShopping();
                      }}
                      className="cart__button cart__button--keep_shopping"
                    >
                      {itemsData.keepShopping}
                    </Button>
                  </div>
                  <Button
                    testAutoId={'clearCart'}
                    className="order__clear--btn"
                    onClick={() => setClearCart(true)}
                  >
                    {itemsData.clearCart}
                  </Button>
                </div>
                <QuickAddOn
                  onClickQuickAddonHandler={onClickQuickAddonHandler}
                  dictionaryLables={dictionaryLables}
                  quickaddonResponse={quickAddOns}
                  fromModalCarousel={false}
                />
                <div className="order__instructions">
                  <h4
                    className="order__instructions__title"
                    data-testauto-id="cart-special-ins-title"
                  >
                    {itemsData.specialInstructions}
                  </h4>
                  <div className="order__instructions__details">
                    <label data-testauto-id="order-note-optional">
                      {itemsData.orderInstructions}
                    </label>
                    <textarea
                      maxLength={MAX_LENGTH}
                      ref={textareaRef}
                      className="special_instructions"
                      name="textarea"
                      onChange={e => {
                        if (e.target.value.length <= MAX_LENGTH) {
                          setInstruction(e.target.value);
                          getSpecialInstruction(e.target.value, utensilsDataUpdated);
                        }
                      }}
                      value={instruction}
                      aria-label={itemsSummary.adaDictionaryData.enterSpecialAda}
                      data-testauto-id="cart-order-notes-textarea"
                    ></textarea>
                    <span
                      className="order__instructions__limit"
                      data-testauto-id="order-max-character"
                    >{`${MAX_LENGTH - instruction.length} ${itemsData.character}`}</span>
                  </div>
                </div>
                {utensilsDataUpdated?.length > 0 && utensilsFlag && (
                  <div className="utensil__selection">
                    <Heading
                      className="utensil__selection__title"
                      title={itemsData.utensilsTitle}
                      level="h4"
                      testAutoId="utensil-section-title"
                    />
                    <ul className="utensils__list">
                      {utensilsDataUpdated.map((item: any, index: any) => {
                        return (
                          <li key={index} className="utensils__item">
                            <div className="utensils">
                              <Input
                                className="utensil__checkbox"
                                type="checkbox"
                                id={item.id}
                                name={item.displayText}
                                checked={item.defaultValue}
                                onChange={() => handleOnChange(index, utensilsDataUpdated)}
                              />
                              <Heading
                                className="utensils__label"
                                title={item.displayText}
                                level="span"
                                testAutoId={`utensil-display-text-${index}`}
                              />
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="order__summary__container">
              <OrderSummary
                onPromoClose={onPromoClose}
                summaryData={summaryData}
                orderTypeDetails={orderTypeDetails}
                deliverySelection={deliverySelection}
                summaryLabels={summaryLabels}
                dictionaryLables={dictionaryLables}
                applyPromoCode={applyPromoCode}
                notOfferedData={notOfferedData}
                promoResponse={invalidPromoData}
                orderViewModel={orderViewModel}
                refetchOrderManagement={refetchOrderManagement}
                adaDictionaryData={itemsSummary.adaDictionaryData}
              />
            </div>
          </>
        )}

        {((items && isModal) || !_isEmpty(error) || clearCart || isRemoveItem) && (
          <ErrorPopup
            error={error}
            flags={flags}
            errorDictionary={errorDictionary}
            items={items}
            outOfStockModal={isModal}
            clearCart={clearCart}
            closeModal={closeModal}
            unavailableComoboWarning={unavailableComoboWarning}
            removeItemsFromCart={removeItemsFromCart}
            itemsData={itemsData}
            isRemoveItem={isRemoveItem}
            handleRemoveSides={handleRemoveSides}
            handleOkClick={handleOkClick}
            ariaLabelText={dictionaryLables?.ariaLabelText}
          />
        )}
        {componentRenderCheck(
          multipleAndCheck(curbsidePopup, orderTypeDetails),
          <div className="vehicle_selection_popup">
            <VehicleSelection
              data={orderTypeDetails.curbDetails}
              setCurbsidePopup={setCurbsidePopup}
              setSelectedVehicle={setSelectedVehicle}
              setSelectedColor={setSelectedColor}
              selectedVehicle={selectedVehicle}
              selectedColor={selectedColor}
              saveCurbsideData={saveCurbsideData}
            />
          </div>,
        )}
      </div>
      {componentRenderCheck(
        showYellowSticker,
        <YellowSticker
          sweetGotRewards={itemsData.sweetGotRewards}
          viewRewards={itemsData.viewRewards}
        />,
      )}
    </div>
  );
};
export const addToCartItems = (
  quantity: number,
  itemDetails: any,
  masterProductId: string | null,
  options: any[] = [],
  offerId: string | null = null,
) => {
  const {
    productName,
    name,
    upsellCategoryId,
    productId,
    productOriginSource,
    categoryName,
    categoryId,
    subCategory,
    isCookie,
    upSellBaseProduct
  } = itemDetails;
  const analyticsProductName = ternaryCheck(isCookie, _get(options, ['0', 'optionName'], ''), productName)
  return {
    AddCartItems: [
      {
        analytics: {
          CategoryId: categoryId || upsellCategoryId,
          CategoryName: categoryName,
          ProductName: analyticsProductName || name,
          ProductOriginSource: productOriginSource,
          offerId: offerId,
          masterProductId: masterProductId,
          subCategory: subCategory,
          isCookie: isCookie,
          upSellBaseProduct: upSellBaseProduct,
        },
        options: options,
        productId: productId,
        productName: productName || name,
        quantity: quantity,
        imageUrl: '',
      },
    ],
  };
};

export default OrderManagement;
