import React, { useEffect, useState } from 'react';
import Button from '../../Atoms/Button/Button';
import Modal from '../Modal/Modal';
import _get from 'lodash/get';
import useStore from '../../../store/useStore';
import { OK_TEXT_BTN } from '../../../constants/ContextConstants';

const ErrorModal = ({
  error,
  okText,
  value,
  analytics,
  oldStyleAnalytics,
  updateModal = () => {
    console.log('No update Modal function was provided.');
  },
}: any) => {
  const [showModal, setModal] = useState(true);
  const errorDisctionay: any = _get(error, 'response.data.error', null);
  const { setAnalyticsProducts, setAttemptTracker, setContextLoader } = useStore();
  useEffect(() => {
    if (errorDisctionay) {
      if (analytics && oldStyleAnalytics) {
        analytics.loyalErrorHandle(errorDisctionay.message);
      } else {
        setAnalyticsProducts({
          errorMessage: errorDisctionay.message || '',
          errorMessageMW: 1,
          errorMessageEvent: 1,
          parentErrorCode: errorDisctionay.parentCode || '',
          childErrorCode: errorDisctionay.errorCode || '',
          virtualName: 'error warning modal',
        });
        setAttemptTracker([{ name: value, isClickable: true }, { name: 'errorMessage' }]);
      }
    }
  }, [errorDisctionay]);

  const onClose = () => {
    updateModal();
    setAnalyticsProducts({});
    setModal(false);
  };

  return (
    <Modal
      data-testauto-id={'errormodal'}
      isShown={showModal}
      isClosebtn={true}
      title={errorDisctionay.title || ''}
      description={errorDisctionay.message || errorDisctionay.messageBody || ''}
      onButtonClick={onClose}
    >
      <div>
        <Button testAutoId={OK_TEXT_BTN} className="btn w-100"
          onClick={() => {
            onClose();
            setContextLoader({
              buttonId: OK_TEXT_BTN,
              isLoading: true,
            });
          }}>
          {okText}
        </Button>
      </div>
    </Modal>
  );
};

export default ErrorModal;
