import React, { useEffect, useState } from 'react';
import Button from '../../Atoms/Button/Button';
import Modal from '../Modal/Modal';
import useStore from '../../../store/useStore';

const InfoModal = ({ error, okText,value }: any) => {
  const [showModal, setModal] = useState(true);
  const { setAnalyticsProducts, setAttemptTracker } = useStore();
  useEffect(()=>{
    setAnalyticsProducts({
      errorMessage: error.message ||'',
      errorMessageMW: 1,
      errorMessageEvent: 1,
      parentErrorCode: error.parentCode || 'n/a',
      childErrorCode: error.errorCode || 'n/a',
      virtualName: 'error warning modal',
    });
    setAttemptTracker([{ name: value || '', isClickable: true }, { name: 'errorMessage' }]);
  },[error])
  const onClose = () => {
    setAnalyticsProducts({});
    setModal(false);
  };

  return (
    <Modal
      data-testauto-id = {'modal-okText'}
      isShown={showModal}
      isClosebtn={true}
      title={error.title}
      description={error.message}
      onButtonClick={onClose}
    >
      <div>
        <Button testAutoId={`${okText}`} className="btn w-100" onClick={onClose}>
          {okText}
        </Button>
      </div>
    </Modal>
  );
};

export default InfoModal;
